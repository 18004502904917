@import 'layouts/theme.scss';
.modal-new-virtual-classes {
  .ant-modal-title {
    padding-right: 15px;
  }
  .ant-card-bordered {
    border: none;
  }
  .ui-form-control {
    button {
      @include themify($themes) {
        background-color: themed('secondary-color') !important;
        border:  themed('secondary-color') !important;
      }
      &:hover{
        @include themify($themes) {
          color: themed('light') !important;
        }
      }
    }
  }
}

.virtual-classes-name {
  margin-bottom: 20px;
}

.layout-new-session {
  &__content {
    position: relative;

    .icon-arrow {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }

  .date-time {
    .ant-calendar-picker {
      min-width: 100%;

      &-clear, &-icon {
        right: 10px;
      }
    }
  }

  @mixin flexStretch {
    display: flex;
    align-items: stretch;
  }

  @media screen and (max-width: 575px){
    .md-full-width {
      @include flexStretch;
    }

    .ant-duration-time-picker-wrapper {
      @include flexStretch;

      .ant-form-item {
        flex-direction: column;
        @include flexStretch;

        .ant-form-item-control-wrapper {
          margin-top: auto;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .icon-arrow {
      bottom: 16px;
      transform: translateX(-50%);
      top: inherit;
    }

    label {
      font-size: 12px;
    }
  }
}

.virtual-classes-public {
  .ant-form-item {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 575px) {
  .virtual-classes-name {
    margin-bottom: 0;

    label span {
      font-size: 12px;
    }
  }

  .ant-form-item label {
    margin-top: 15px;
  }
}
