.reorder-question-type-word {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__selection {
    padding: 10px;
  }

  &__selection-block {
    display: inline-block;
    margin: 5px;
  }

  &__answer-height-maintainer {
    min-height: 50px;
    display: inline-block;
  }

  &__section {
    min-width: 350px;
    text-align: center;
  }

  &__section-divider {
    height: 20px;
  }

  &__help-text {
    padding: 0 5px;
    margin: 10px 0;
  }

  &__underline {
    margin: 10px 0 !important;
    width: 100%;
  }

  &__placeholder {
    text-align: center;
    width: 90%;
    display: inline-block;
  }
}
