@import "../../configs/css-configuration.scss";
@import "./app-common.scss";
@import 'layouts/theme.scss';
@import 'layouts/themes/theme-gj.scss';

html, body {
  -webkit-font-smoothing: antialiased !important;
  font-size: 11pt !important;
  //background-color: #f2f2f2;
  background: theme-color($key: 'student-dashboard-background');
}
.ui-app {
  background-color: $main-color-bg;
  position: relative;
  overflow: hidden;
}
.ui-app-body-main {
  padding: 30px 30px 0 30px;
  max-width: $max-width-content;
}
.ui-right-frame {
  padding-top: $top-menu-height;
}

.transition-appear {
  opacity: 0.01;
}

.transition-appear.transition-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}
