.view-open-ended-answer {
  padding: 0 15px;
  overflow: auto;

  &__title {
    font-size: 120%;
    font-weight: bold;
  }

  &__content {
    padding: 0 20px;
  }

  &__attachments {
    padding: 0 20px;
    list-style: none;
  }
}