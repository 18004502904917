@import 'layouts/theme.scss';
$fbButtonBg: #3b5998;
$ggButtonBg: #ea4335;

.social-login {
  display: flex !important;
  justify-content: center;
  align-items: center;
  &-item {
    button {
      font-size: 25px;
      color: #fff;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 5px;
      outline: none;
      border: none;
      transition: all 0.3s;
      &.facebook {
        background-color: $fbButtonBg;
      }
      &.google {
        background-color: $ggButtonBg;
      }
      &:hover {
        color: #fff;
        @include themify($themes) {
          background-color: theme-color();
        }
      }
    }
  }
}
