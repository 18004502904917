.normal-exercise-display {
  &__items {
    float: left;
    margin: 0;
    width: 100%;
    padding: 0 0 35px;
  }

  &__result-separator {
    border-top: solid 1px;
    border-bottom: solid 1px;
    background-color: #e0e0e0;
    color: #e0e0e0;
  }

  &__result {
    margin-bottom: 1em;
  }

  &__message {
    color: red;
  }
}
