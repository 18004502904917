@import "layouts/theme.scss";
.temis-profile-container {
  padding: 10px;
  margin: 0 auto;
  // maxWidth: 1300;
  width: 100%;
  box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.75);
  .ant-card.ant-card-bordered {
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border: none;
    height: 100%;
  }
  label,
  .ant-form-item-no-colon {
    color: rgba(0, 0, 0, 0.85) !important;
  }
  label.ant-radio-wrapper {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  @media screen and (min-width: 768px) {
    padding: 20px;
    margin: 10px auto;
  }
  input:read-only:not(.ant-calendar-picker-input) {
    background: #f5f5f5;
    cursor: not-allowed;
    color: gray;
    &:hover {
      border-color: inherit;
    }
  }
  label.checkbox-read-only {
    &,
    span {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .invalid-button {
    cursor: not-allowed !important;
    * {
      pointer-events: none;
    }
    button {
      background-color: gray !important;
    }
  }
  .ant-radio-inner{
    border-color: rgba(0, 0, 0, 0.45);
  }
  .submit-btn-wrapper:not(.invalid-button) {
    button {
      @include themify($themes) {
        background-color: themed('btn-primary-bg') !important;
      }
    }
  }
}
