@import "layouts/theme.scss";
.add-comment-wrapper {
  .ant-comment-content-author {
    display: contents;
  }
  .add-comment {
    //padding: 10px 0;
    display: flex;
    flex-direction: row;

    .avatar {
      margin-top: 4px;
      min-width: 40px;
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 4px 0 0;
      box-sizing: border-box;

      .input-text-label {
        display: none;
      }

      .input-text {
        border: solid 1px #dce4e9;
        padding: 10px;
        border-radius: 4px;
        min-height: 48px;
        resize: none;
        outline: none;
        box-sizing: border-box;

        &:focus {
          border-color: #00bcd4;
          box-sizing: border-box;
        }
      }
    }

    .send-button {
      border: none;
      border-radius: 4px !important;
      color: white;
      width: 90px;
      height: 50px;
      outline: none;
    }

    .active-button {
      background: #00bcd4;
    }

    .active-button-admin {
      background: #00bcd4;
    }

    .not-active-button {
      background: #a5a5a5;
    }
  }
}