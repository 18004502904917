@import 'layouts/theme.scss';
.gj-register-form {
  h3 {
    margin: 0;
    padding: 0;
    color: #000000;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
  }
  .indicator {
    @include themify($themes) {
      background-color: themed('btn-login-bg');
    }

    width: 30px;
    height: 3px;
    padding: 0;
  }
}
