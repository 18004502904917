@import url(~material-icons/css/material-icons.min.css);
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(https://fonts.gstatic.com/s/materialicons/v21/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format("woff2"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

html {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased; }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v5/zhcz-_WihjSQC0oHJ9TCYAzyDMXhdD8sAj6OAJTFsBI.woff2) format("woff2"), url(https://fonts.gstatic.com/s/montserrat/v5/zhcz-_WihjSQC0oHJ9TCYL3hpw3pgy2gAi-Ip7WPMi0.woff) format("woff"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat-Bold"), url(https://fonts.gstatic.com/s/montserrat/v5/IQHow_FEYlDC4Gzy_m8fcmaVI6zN22yiurzcBKxPjFE.woff2) format("woff2"), url(https://fonts.gstatic.com/s/montserrat/v5/IQHow_FEYlDC4Gzy_m8fcnbFhgvWbfSbdVg11QabG8w.woff); }

svg:not(:root) {
  overflow: hidden; }

[class^="is-icon-"], [class*=" is-icon-"] {
  height: 18px;
  width: 18px;
  display: inline-block;
  fill: currentColor;
  margin-bottom: -5px; }

.outer-center-box {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%; }

.middle-center-box {
  display: table-cell;
  vertical-align: middle; }

.inner-center-box {
  margin-left: auto;
  margin-right: auto; }

.margin-right15px {
  margin-right: 15px; }

.center-block-panel {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center; }

a {
  text-decoration: none;
  display: inline-block; }

a:hover {
  text-decoration: none; }

.m-t-0 {
  margin-top: 0px; }

.m-r-0 {
  margin-right: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-t-5 {
  margin-top: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-r-20 {
  margin-right: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-t-25 {
  margin-top: 25px; }

.m-r-25 {
  margin-right: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-t-30 {
  margin-top: 30px; }

.m-r-30 {
  margin-right: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-t-35 {
  margin-top: 35px; }

.m-r-35 {
  margin-right: 35px; }

.m-l-35 {
  margin-left: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-t-40 {
  margin-top: 40px; }

.m-r-40 {
  margin-right: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-t-45 {
  margin-top: 45px; }

.m-r-45 {
  margin-right: 45px; }

.m-l-45 {
  margin-left: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-t-50 {
  margin-top: 50px; }

.m-r-50 {
  margin-right: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-t-55 {
  margin-top: 55px; }

.m-r-55 {
  margin-right: 55px; }

.m-l-55 {
  margin-left: 55px; }

.m-b-55 {
  margin-bottom: 55px; }

.m-t-60 {
  margin-top: 60px; }

.m-r-60 {
  margin-right: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-t-65 {
  margin-top: 65px; }

.m-r-65 {
  margin-right: 65px; }

.m-l-65 {
  margin-left: 65px; }

.m-b-65 {
  margin-bottom: 65px; }

.m-t-70 {
  margin-top: 70px; }

.m-r-70 {
  margin-right: 70px; }

.m-l-70 {
  margin-left: 70px; }

.m-b-70 {
  margin-bottom: 70px; }

.m-t-75 {
  margin-top: 75px; }

.m-r-75 {
  margin-right: 75px; }

.m-l-75 {
  margin-left: 75px; }

.m-b-75 {
  margin-bottom: 75px; }

.m-t-80 {
  margin-top: 80px; }

.m-r-80 {
  margin-right: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-t-85 {
  margin-top: 85px; }

.m-r-85 {
  margin-right: 85px; }

.m-l-85 {
  margin-left: 85px; }

.m-b-85 {
  margin-bottom: 85px; }

.m-t-90 {
  margin-top: 90px; }

.m-r-90 {
  margin-right: 90px; }

.m-l-90 {
  margin-left: 90px; }

.m-b-90 {
  margin-bottom: 90px; }

.m-t-95 {
  margin-top: 95px; }

.m-r-95 {
  margin-right: 95px; }

.m-l-95 {
  margin-left: 95px; }

.m-b-95 {
  margin-bottom: 95px; }

.m-t-100 {
  margin-top: 100px; }

.m-r-100 {
  margin-right: 100px; }

.m-l-100 {
  margin-left: 100px; }

.m-b-100 {
  margin-bottom: 100px; }

.d-table {
  display: table; }

.d-row {
  display: table-row; }

.d-cell {
  display: table-cell; }

.d-column {
  display: table-column; }

html, body {
  -webkit-font-smoothing: antialiased !important;
  font-size: 11pt !important;
  background: url("/media/images/gojapan/texture.png"); }

.ui-app {
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden; }

.ui-app-body-main {
  padding: 30px 30px 0 30px;
  max-width: 1300px; }

.ui-right-frame {
  padding-top: 52px; }

.transition-appear {
  opacity: 0.01; }

.transition-appear.transition-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

.gj-bg, .gi-layout-wrapper {
  background: url("/media/images/gojapan/texture.png");
  background-origin: content-box !important; }

.gj-bg-color {
  background-color: #f2f2f2; }

html, body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0; }

.ui-root-layout {
  position: relative; }

a {
  color: #000; }

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  border: none !important;
  background-color: inherit !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: inset 0 0 0 9999px white !important; }

.truncated-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.truncated-text:hover {
  overflow: visible;
  white-space: normal; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center !important; }

.text-transform {
  text-transform: uppercase; }

.text-bold {
  font-weight: bold !important; }

.text-muted {
  color: #9b9b9b; }

.cursor-pointer {
  cursor: pointer; }

.center {
  display: flex;
  justify-content: center;
  align-items: center; }

.buton-delete :hover {
  color: #ca3712; }

table td,
table th {
  white-space: normal !important;
  padding-left: 6px !important;
  padding-right: 6px !important; }

.form-validate-error {
  bottom: 15px;
  font-size: 12px;
  line-height: 12px;
  color: #f44336;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.icon-button {
  z-index: 10; }

ul.item-table-panel {
  list-style: none;
  padding: 0;
  margin: 0; }
  ul.item-table-panel li {
    padding: 0 0 0 5px; }

.default-login-form-wrapper .content-dialog {
  width: 406px !important; }

.default-login-form-wrapper .default-login-form {
  padding: 30px 60px; }
  .default-login-form-wrapper .default-login-form h3 {
    margin-top: 0; }
  .default-login-form-wrapper .default-login-form h3,
  .default-login-form-wrapper .default-login-form input {
    display: block; }
  .default-login-form-wrapper .default-login-form input,
  .default-login-form-wrapper .default-login-form input:hover,
  .default-login-form-wrapper .default-login-form input:focus {
    border: 1px solid #d9d9d9 !important;
    padding: 6px 12px !important;
    width: 100%;
    margin-bottom: 18px;
    height: auto !important; }
  .default-login-form-wrapper .default-login-form .login-btn {
    background: #dcab18;
    border: none;
    color: #fff;
    padding: 5px 25px;
    margin-top: 0;
    font-size: 14px;
    cursor: pointer; }
  .default-login-form-wrapper .default-login-form a {
    cursor: pointer; }

.etec-login-form-wrapper .content-dialog {
  width: 520px !important;
  top: -100px; }

@media screen and (max-width: 555px) {
  .etec-login-form-wrapper .content-dialog {
    width: 350px !important;
    top: -100px; } }

@media screen and (max-width: 480px) {
  .default-login-form-wrapper .content-dialog {
    width: 90% !important; } }

@media screen and (max-width: 375px) {
  .etec-login-form-wrapper .content-dialog {
    width: 310px !important;
    top: -100px; } }

.white-box {
  background: white;
  padding: 20px;
  margin-bottom: 10px; }
  .white-box form {
    margin-bottom: 0;
    padding-bottom: 0; }

button span {
  text-transform: none !important;
  font-weight: normal !important; }

.m-p-0 {
  margin: 0;
  padding: 0; }

.wrap-td {
  white-space: unset !important;
  text-overflow: unset !important; }

a {
  text-decoration: none; }

.searchResultsDeletedRow {
  background-color: orange !important; }

.display-none {
  display: none; }

.displayFlex {
  display: flex; }

.flex-container {
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important; }
  .flex-container .flex-item {
    padding: 2px !important;
    flex-grow: 1 !important; }
  .flex-container .submit {
    width: 100px; }

.flex-container-wrap {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  flex: auto; }
  .flex-container-wrap .flex-item {
    display: inline-block;
    padding: 2px;
    flex-grow: 1; }

.table-border table {
  width: 100%; }

.table-border table,
.table-border th,
.table-border td {
  border: 1px solid #e0e0e0; }

.table-body-align-top td {
  vertical-align: top;
  padding-top: 10px; }

.table-cell-list {
  padding-left: 15px; }

.white {
  color: white; }

.white-background {
  background-color: white; }
  .white-background .elementGroup__title {
    color: rgba(0, 0, 0, 0.65) !important; }

.whitebox {
  background: white;
  padding: 10px 15px; }

.dialog-content {
  background: #f0f2f5 !important; }
  .dialog-content .dialog-content {
    padding-top: 24px !important; }

.elementGroup {
  margin-bottom: 10px; }

.elementGroupBody {
  padding: 10px 15px 20px; }

.cleanPadding {
  padding: 0 !important; }

.p-0 {
  padding: 0px !important; }

.p-t-0 {
  padding-top: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.m-0 {
  margin: 0px !important; }

.m-t-0 {
  margin-top: 0px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.m-b-0 {
  margin-bottom: 0px !important; }

.m-l-0 {
  margin-left: 0px !important; }

.p-1 {
  padding: 1px !important; }

.p-t-1 {
  padding-top: 1px !important; }

.p-r-1 {
  padding-right: 1px !important; }

.p-b-1 {
  padding-bottom: 1px !important; }

.p-l-1 {
  padding-left: 1px !important; }

.m-1 {
  margin: 1px !important; }

.m-t-1 {
  margin-top: 1px !important; }

.m-r-1 {
  margin-right: 1px !important; }

.m-b-1 {
  margin-bottom: 1px !important; }

.m-l-1 {
  margin-left: 1px !important; }

.p-2 {
  padding: 2px !important; }

.p-t-2 {
  padding-top: 2px !important; }

.p-r-2 {
  padding-right: 2px !important; }

.p-b-2 {
  padding-bottom: 2px !important; }

.p-l-2 {
  padding-left: 2px !important; }

.m-2 {
  margin: 2px !important; }

.m-t-2 {
  margin-top: 2px !important; }

.m-r-2 {
  margin-right: 2px !important; }

.m-b-2 {
  margin-bottom: 2px !important; }

.m-l-2 {
  margin-left: 2px !important; }

.p-3 {
  padding: 3px !important; }

.p-t-3 {
  padding-top: 3px !important; }

.p-r-3 {
  padding-right: 3px !important; }

.p-b-3 {
  padding-bottom: 3px !important; }

.p-l-3 {
  padding-left: 3px !important; }

.m-3 {
  margin: 3px !important; }

.m-t-3 {
  margin-top: 3px !important; }

.m-r-3 {
  margin-right: 3px !important; }

.m-b-3 {
  margin-bottom: 3px !important; }

.m-l-3 {
  margin-left: 3px !important; }

.p-4 {
  padding: 4px !important; }

.p-t-4 {
  padding-top: 4px !important; }

.p-r-4 {
  padding-right: 4px !important; }

.p-b-4 {
  padding-bottom: 4px !important; }

.p-l-4 {
  padding-left: 4px !important; }

.m-4 {
  margin: 4px !important; }

.m-t-4 {
  margin-top: 4px !important; }

.m-r-4 {
  margin-right: 4px !important; }

.m-b-4 {
  margin-bottom: 4px !important; }

.m-l-4 {
  margin-left: 4px !important; }

.p-5 {
  padding: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.m-5 {
  margin: 5px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.p-6 {
  padding: 6px !important; }

.p-t-6 {
  padding-top: 6px !important; }

.p-r-6 {
  padding-right: 6px !important; }

.p-b-6 {
  padding-bottom: 6px !important; }

.p-l-6 {
  padding-left: 6px !important; }

.m-6 {
  margin: 6px !important; }

.m-t-6 {
  margin-top: 6px !important; }

.m-r-6 {
  margin-right: 6px !important; }

.m-b-6 {
  margin-bottom: 6px !important; }

.m-l-6 {
  margin-left: 6px !important; }

.p-7 {
  padding: 7px !important; }

.p-t-7 {
  padding-top: 7px !important; }

.p-r-7 {
  padding-right: 7px !important; }

.p-b-7 {
  padding-bottom: 7px !important; }

.p-l-7 {
  padding-left: 7px !important; }

.m-7 {
  margin: 7px !important; }

.m-t-7 {
  margin-top: 7px !important; }

.m-r-7 {
  margin-right: 7px !important; }

.m-b-7 {
  margin-bottom: 7px !important; }

.m-l-7 {
  margin-left: 7px !important; }

.p-8 {
  padding: 8px !important; }

.p-t-8 {
  padding-top: 8px !important; }

.p-r-8 {
  padding-right: 8px !important; }

.p-b-8 {
  padding-bottom: 8px !important; }

.p-l-8 {
  padding-left: 8px !important; }

.m-8 {
  margin: 8px !important; }

.m-t-8 {
  margin-top: 8px !important; }

.m-r-8 {
  margin-right: 8px !important; }

.m-b-8 {
  margin-bottom: 8px !important; }

.m-l-8 {
  margin-left: 8px !important; }

.p-9 {
  padding: 9px !important; }

.p-t-9 {
  padding-top: 9px !important; }

.p-r-9 {
  padding-right: 9px !important; }

.p-b-9 {
  padding-bottom: 9px !important; }

.p-l-9 {
  padding-left: 9px !important; }

.m-9 {
  margin: 9px !important; }

.m-t-9 {
  margin-top: 9px !important; }

.m-r-9 {
  margin-right: 9px !important; }

.m-b-9 {
  margin-bottom: 9px !important; }

.m-l-9 {
  margin-left: 9px !important; }

.p-10 {
  padding: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.m-10 {
  margin: 10px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.p-11 {
  padding: 11px !important; }

.p-t-11 {
  padding-top: 11px !important; }

.p-r-11 {
  padding-right: 11px !important; }

.p-b-11 {
  padding-bottom: 11px !important; }

.p-l-11 {
  padding-left: 11px !important; }

.m-11 {
  margin: 11px !important; }

.m-t-11 {
  margin-top: 11px !important; }

.m-r-11 {
  margin-right: 11px !important; }

.m-b-11 {
  margin-bottom: 11px !important; }

.m-l-11 {
  margin-left: 11px !important; }

.p-12 {
  padding: 12px !important; }

.p-t-12 {
  padding-top: 12px !important; }

.p-r-12 {
  padding-right: 12px !important; }

.p-b-12 {
  padding-bottom: 12px !important; }

.p-l-12 {
  padding-left: 12px !important; }

.m-12 {
  margin: 12px !important; }

.m-t-12 {
  margin-top: 12px !important; }

.m-r-12 {
  margin-right: 12px !important; }

.m-b-12 {
  margin-bottom: 12px !important; }

.m-l-12 {
  margin-left: 12px !important; }

.p-13 {
  padding: 13px !important; }

.p-t-13 {
  padding-top: 13px !important; }

.p-r-13 {
  padding-right: 13px !important; }

.p-b-13 {
  padding-bottom: 13px !important; }

.p-l-13 {
  padding-left: 13px !important; }

.m-13 {
  margin: 13px !important; }

.m-t-13 {
  margin-top: 13px !important; }

.m-r-13 {
  margin-right: 13px !important; }

.m-b-13 {
  margin-bottom: 13px !important; }

.m-l-13 {
  margin-left: 13px !important; }

.p-14 {
  padding: 14px !important; }

.p-t-14 {
  padding-top: 14px !important; }

.p-r-14 {
  padding-right: 14px !important; }

.p-b-14 {
  padding-bottom: 14px !important; }

.p-l-14 {
  padding-left: 14px !important; }

.m-14 {
  margin: 14px !important; }

.m-t-14 {
  margin-top: 14px !important; }

.m-r-14 {
  margin-right: 14px !important; }

.m-b-14 {
  margin-bottom: 14px !important; }

.m-l-14 {
  margin-left: 14px !important; }

.p-15 {
  padding: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.m-15 {
  margin: 15px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.p-16 {
  padding: 16px !important; }

.p-t-16 {
  padding-top: 16px !important; }

.p-r-16 {
  padding-right: 16px !important; }

.p-b-16 {
  padding-bottom: 16px !important; }

.p-l-16 {
  padding-left: 16px !important; }

.m-16 {
  margin: 16px !important; }

.m-t-16 {
  margin-top: 16px !important; }

.m-r-16 {
  margin-right: 16px !important; }

.m-b-16 {
  margin-bottom: 16px !important; }

.m-l-16 {
  margin-left: 16px !important; }

.p-17 {
  padding: 17px !important; }

.p-t-17 {
  padding-top: 17px !important; }

.p-r-17 {
  padding-right: 17px !important; }

.p-b-17 {
  padding-bottom: 17px !important; }

.p-l-17 {
  padding-left: 17px !important; }

.m-17 {
  margin: 17px !important; }

.m-t-17 {
  margin-top: 17px !important; }

.m-r-17 {
  margin-right: 17px !important; }

.m-b-17 {
  margin-bottom: 17px !important; }

.m-l-17 {
  margin-left: 17px !important; }

.p-18 {
  padding: 18px !important; }

.p-t-18 {
  padding-top: 18px !important; }

.p-r-18 {
  padding-right: 18px !important; }

.p-b-18 {
  padding-bottom: 18px !important; }

.p-l-18 {
  padding-left: 18px !important; }

.m-18 {
  margin: 18px !important; }

.m-t-18 {
  margin-top: 18px !important; }

.m-r-18 {
  margin-right: 18px !important; }

.m-b-18 {
  margin-bottom: 18px !important; }

.m-l-18 {
  margin-left: 18px !important; }

.p-19 {
  padding: 19px !important; }

.p-t-19 {
  padding-top: 19px !important; }

.p-r-19 {
  padding-right: 19px !important; }

.p-b-19 {
  padding-bottom: 19px !important; }

.p-l-19 {
  padding-left: 19px !important; }

.m-19 {
  margin: 19px !important; }

.m-t-19 {
  margin-top: 19px !important; }

.m-r-19 {
  margin-right: 19px !important; }

.m-b-19 {
  margin-bottom: 19px !important; }

.m-l-19 {
  margin-left: 19px !important; }

.p-20 {
  padding: 20px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.m-20 {
  margin: 20px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.p-21 {
  padding: 21px !important; }

.p-t-21 {
  padding-top: 21px !important; }

.p-r-21 {
  padding-right: 21px !important; }

.p-b-21 {
  padding-bottom: 21px !important; }

.p-l-21 {
  padding-left: 21px !important; }

.m-21 {
  margin: 21px !important; }

.m-t-21 {
  margin-top: 21px !important; }

.m-r-21 {
  margin-right: 21px !important; }

.m-b-21 {
  margin-bottom: 21px !important; }

.m-l-21 {
  margin-left: 21px !important; }

.p-22 {
  padding: 22px !important; }

.p-t-22 {
  padding-top: 22px !important; }

.p-r-22 {
  padding-right: 22px !important; }

.p-b-22 {
  padding-bottom: 22px !important; }

.p-l-22 {
  padding-left: 22px !important; }

.m-22 {
  margin: 22px !important; }

.m-t-22 {
  margin-top: 22px !important; }

.m-r-22 {
  margin-right: 22px !important; }

.m-b-22 {
  margin-bottom: 22px !important; }

.m-l-22 {
  margin-left: 22px !important; }

.p-23 {
  padding: 23px !important; }

.p-t-23 {
  padding-top: 23px !important; }

.p-r-23 {
  padding-right: 23px !important; }

.p-b-23 {
  padding-bottom: 23px !important; }

.p-l-23 {
  padding-left: 23px !important; }

.m-23 {
  margin: 23px !important; }

.m-t-23 {
  margin-top: 23px !important; }

.m-r-23 {
  margin-right: 23px !important; }

.m-b-23 {
  margin-bottom: 23px !important; }

.m-l-23 {
  margin-left: 23px !important; }

.p-24 {
  padding: 24px !important; }

.p-t-24 {
  padding-top: 24px !important; }

.p-r-24 {
  padding-right: 24px !important; }

.p-b-24 {
  padding-bottom: 24px !important; }

.p-l-24 {
  padding-left: 24px !important; }

.m-24 {
  margin: 24px !important; }

.m-t-24 {
  margin-top: 24px !important; }

.m-r-24 {
  margin-right: 24px !important; }

.m-b-24 {
  margin-bottom: 24px !important; }

.m-l-24 {
  margin-left: 24px !important; }

.p-25 {
  padding: 25px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.m-25 {
  margin: 25px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.p-26 {
  padding: 26px !important; }

.p-t-26 {
  padding-top: 26px !important; }

.p-r-26 {
  padding-right: 26px !important; }

.p-b-26 {
  padding-bottom: 26px !important; }

.p-l-26 {
  padding-left: 26px !important; }

.m-26 {
  margin: 26px !important; }

.m-t-26 {
  margin-top: 26px !important; }

.m-r-26 {
  margin-right: 26px !important; }

.m-b-26 {
  margin-bottom: 26px !important; }

.m-l-26 {
  margin-left: 26px !important; }

.p-27 {
  padding: 27px !important; }

.p-t-27 {
  padding-top: 27px !important; }

.p-r-27 {
  padding-right: 27px !important; }

.p-b-27 {
  padding-bottom: 27px !important; }

.p-l-27 {
  padding-left: 27px !important; }

.m-27 {
  margin: 27px !important; }

.m-t-27 {
  margin-top: 27px !important; }

.m-r-27 {
  margin-right: 27px !important; }

.m-b-27 {
  margin-bottom: 27px !important; }

.m-l-27 {
  margin-left: 27px !important; }

.p-28 {
  padding: 28px !important; }

.p-t-28 {
  padding-top: 28px !important; }

.p-r-28 {
  padding-right: 28px !important; }

.p-b-28 {
  padding-bottom: 28px !important; }

.p-l-28 {
  padding-left: 28px !important; }

.m-28 {
  margin: 28px !important; }

.m-t-28 {
  margin-top: 28px !important; }

.m-r-28 {
  margin-right: 28px !important; }

.m-b-28 {
  margin-bottom: 28px !important; }

.m-l-28 {
  margin-left: 28px !important; }

.p-29 {
  padding: 29px !important; }

.p-t-29 {
  padding-top: 29px !important; }

.p-r-29 {
  padding-right: 29px !important; }

.p-b-29 {
  padding-bottom: 29px !important; }

.p-l-29 {
  padding-left: 29px !important; }

.m-29 {
  margin: 29px !important; }

.m-t-29 {
  margin-top: 29px !important; }

.m-r-29 {
  margin-right: 29px !important; }

.m-b-29 {
  margin-bottom: 29px !important; }

.m-l-29 {
  margin-left: 29px !important; }

.p-30 {
  padding: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.m-30 {
  margin: 30px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.p-31 {
  padding: 31px !important; }

.p-t-31 {
  padding-top: 31px !important; }

.p-r-31 {
  padding-right: 31px !important; }

.p-b-31 {
  padding-bottom: 31px !important; }

.p-l-31 {
  padding-left: 31px !important; }

.m-31 {
  margin: 31px !important; }

.m-t-31 {
  margin-top: 31px !important; }

.m-r-31 {
  margin-right: 31px !important; }

.m-b-31 {
  margin-bottom: 31px !important; }

.m-l-31 {
  margin-left: 31px !important; }

.p-32 {
  padding: 32px !important; }

.p-t-32 {
  padding-top: 32px !important; }

.p-r-32 {
  padding-right: 32px !important; }

.p-b-32 {
  padding-bottom: 32px !important; }

.p-l-32 {
  padding-left: 32px !important; }

.m-32 {
  margin: 32px !important; }

.m-t-32 {
  margin-top: 32px !important; }

.m-r-32 {
  margin-right: 32px !important; }

.m-b-32 {
  margin-bottom: 32px !important; }

.m-l-32 {
  margin-left: 32px !important; }

.p-33 {
  padding: 33px !important; }

.p-t-33 {
  padding-top: 33px !important; }

.p-r-33 {
  padding-right: 33px !important; }

.p-b-33 {
  padding-bottom: 33px !important; }

.p-l-33 {
  padding-left: 33px !important; }

.m-33 {
  margin: 33px !important; }

.m-t-33 {
  margin-top: 33px !important; }

.m-r-33 {
  margin-right: 33px !important; }

.m-b-33 {
  margin-bottom: 33px !important; }

.m-l-33 {
  margin-left: 33px !important; }

.p-34 {
  padding: 34px !important; }

.p-t-34 {
  padding-top: 34px !important; }

.p-r-34 {
  padding-right: 34px !important; }

.p-b-34 {
  padding-bottom: 34px !important; }

.p-l-34 {
  padding-left: 34px !important; }

.m-34 {
  margin: 34px !important; }

.m-t-34 {
  margin-top: 34px !important; }

.m-r-34 {
  margin-right: 34px !important; }

.m-b-34 {
  margin-bottom: 34px !important; }

.m-l-34 {
  margin-left: 34px !important; }

.p-35 {
  padding: 35px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.m-35 {
  margin: 35px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.p-36 {
  padding: 36px !important; }

.p-t-36 {
  padding-top: 36px !important; }

.p-r-36 {
  padding-right: 36px !important; }

.p-b-36 {
  padding-bottom: 36px !important; }

.p-l-36 {
  padding-left: 36px !important; }

.m-36 {
  margin: 36px !important; }

.m-t-36 {
  margin-top: 36px !important; }

.m-r-36 {
  margin-right: 36px !important; }

.m-b-36 {
  margin-bottom: 36px !important; }

.m-l-36 {
  margin-left: 36px !important; }

.p-37 {
  padding: 37px !important; }

.p-t-37 {
  padding-top: 37px !important; }

.p-r-37 {
  padding-right: 37px !important; }

.p-b-37 {
  padding-bottom: 37px !important; }

.p-l-37 {
  padding-left: 37px !important; }

.m-37 {
  margin: 37px !important; }

.m-t-37 {
  margin-top: 37px !important; }

.m-r-37 {
  margin-right: 37px !important; }

.m-b-37 {
  margin-bottom: 37px !important; }

.m-l-37 {
  margin-left: 37px !important; }

.p-38 {
  padding: 38px !important; }

.p-t-38 {
  padding-top: 38px !important; }

.p-r-38 {
  padding-right: 38px !important; }

.p-b-38 {
  padding-bottom: 38px !important; }

.p-l-38 {
  padding-left: 38px !important; }

.m-38 {
  margin: 38px !important; }

.m-t-38 {
  margin-top: 38px !important; }

.m-r-38 {
  margin-right: 38px !important; }

.m-b-38 {
  margin-bottom: 38px !important; }

.m-l-38 {
  margin-left: 38px !important; }

.p-39 {
  padding: 39px !important; }

.p-t-39 {
  padding-top: 39px !important; }

.p-r-39 {
  padding-right: 39px !important; }

.p-b-39 {
  padding-bottom: 39px !important; }

.p-l-39 {
  padding-left: 39px !important; }

.m-39 {
  margin: 39px !important; }

.m-t-39 {
  margin-top: 39px !important; }

.m-r-39 {
  margin-right: 39px !important; }

.m-b-39 {
  margin-bottom: 39px !important; }

.m-l-39 {
  margin-left: 39px !important; }

.p-40 {
  padding: 40px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.m-40 {
  margin: 40px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.p-41 {
  padding: 41px !important; }

.p-t-41 {
  padding-top: 41px !important; }

.p-r-41 {
  padding-right: 41px !important; }

.p-b-41 {
  padding-bottom: 41px !important; }

.p-l-41 {
  padding-left: 41px !important; }

.m-41 {
  margin: 41px !important; }

.m-t-41 {
  margin-top: 41px !important; }

.m-r-41 {
  margin-right: 41px !important; }

.m-b-41 {
  margin-bottom: 41px !important; }

.m-l-41 {
  margin-left: 41px !important; }

.p-42 {
  padding: 42px !important; }

.p-t-42 {
  padding-top: 42px !important; }

.p-r-42 {
  padding-right: 42px !important; }

.p-b-42 {
  padding-bottom: 42px !important; }

.p-l-42 {
  padding-left: 42px !important; }

.m-42 {
  margin: 42px !important; }

.m-t-42 {
  margin-top: 42px !important; }

.m-r-42 {
  margin-right: 42px !important; }

.m-b-42 {
  margin-bottom: 42px !important; }

.m-l-42 {
  margin-left: 42px !important; }

.p-43 {
  padding: 43px !important; }

.p-t-43 {
  padding-top: 43px !important; }

.p-r-43 {
  padding-right: 43px !important; }

.p-b-43 {
  padding-bottom: 43px !important; }

.p-l-43 {
  padding-left: 43px !important; }

.m-43 {
  margin: 43px !important; }

.m-t-43 {
  margin-top: 43px !important; }

.m-r-43 {
  margin-right: 43px !important; }

.m-b-43 {
  margin-bottom: 43px !important; }

.m-l-43 {
  margin-left: 43px !important; }

.p-44 {
  padding: 44px !important; }

.p-t-44 {
  padding-top: 44px !important; }

.p-r-44 {
  padding-right: 44px !important; }

.p-b-44 {
  padding-bottom: 44px !important; }

.p-l-44 {
  padding-left: 44px !important; }

.m-44 {
  margin: 44px !important; }

.m-t-44 {
  margin-top: 44px !important; }

.m-r-44 {
  margin-right: 44px !important; }

.m-b-44 {
  margin-bottom: 44px !important; }

.m-l-44 {
  margin-left: 44px !important; }

.p-45 {
  padding: 45px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.m-45 {
  margin: 45px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.p-46 {
  padding: 46px !important; }

.p-t-46 {
  padding-top: 46px !important; }

.p-r-46 {
  padding-right: 46px !important; }

.p-b-46 {
  padding-bottom: 46px !important; }

.p-l-46 {
  padding-left: 46px !important; }

.m-46 {
  margin: 46px !important; }

.m-t-46 {
  margin-top: 46px !important; }

.m-r-46 {
  margin-right: 46px !important; }

.m-b-46 {
  margin-bottom: 46px !important; }

.m-l-46 {
  margin-left: 46px !important; }

.p-47 {
  padding: 47px !important; }

.p-t-47 {
  padding-top: 47px !important; }

.p-r-47 {
  padding-right: 47px !important; }

.p-b-47 {
  padding-bottom: 47px !important; }

.p-l-47 {
  padding-left: 47px !important; }

.m-47 {
  margin: 47px !important; }

.m-t-47 {
  margin-top: 47px !important; }

.m-r-47 {
  margin-right: 47px !important; }

.m-b-47 {
  margin-bottom: 47px !important; }

.m-l-47 {
  margin-left: 47px !important; }

.p-48 {
  padding: 48px !important; }

.p-t-48 {
  padding-top: 48px !important; }

.p-r-48 {
  padding-right: 48px !important; }

.p-b-48 {
  padding-bottom: 48px !important; }

.p-l-48 {
  padding-left: 48px !important; }

.m-48 {
  margin: 48px !important; }

.m-t-48 {
  margin-top: 48px !important; }

.m-r-48 {
  margin-right: 48px !important; }

.m-b-48 {
  margin-bottom: 48px !important; }

.m-l-48 {
  margin-left: 48px !important; }

.p-49 {
  padding: 49px !important; }

.p-t-49 {
  padding-top: 49px !important; }

.p-r-49 {
  padding-right: 49px !important; }

.p-b-49 {
  padding-bottom: 49px !important; }

.p-l-49 {
  padding-left: 49px !important; }

.m-49 {
  margin: 49px !important; }

.m-t-49 {
  margin-top: 49px !important; }

.m-r-49 {
  margin-right: 49px !important; }

.m-b-49 {
  margin-bottom: 49px !important; }

.m-l-49 {
  margin-left: 49px !important; }

.p-50 {
  padding: 50px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.m-50 {
  margin: 50px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.p-51 {
  padding: 51px !important; }

.p-t-51 {
  padding-top: 51px !important; }

.p-r-51 {
  padding-right: 51px !important; }

.p-b-51 {
  padding-bottom: 51px !important; }

.p-l-51 {
  padding-left: 51px !important; }

.m-51 {
  margin: 51px !important; }

.m-t-51 {
  margin-top: 51px !important; }

.m-r-51 {
  margin-right: 51px !important; }

.m-b-51 {
  margin-bottom: 51px !important; }

.m-l-51 {
  margin-left: 51px !important; }

.p-52 {
  padding: 52px !important; }

.p-t-52 {
  padding-top: 52px !important; }

.p-r-52 {
  padding-right: 52px !important; }

.p-b-52 {
  padding-bottom: 52px !important; }

.p-l-52 {
  padding-left: 52px !important; }

.m-52 {
  margin: 52px !important; }

.m-t-52 {
  margin-top: 52px !important; }

.m-r-52 {
  margin-right: 52px !important; }

.m-b-52 {
  margin-bottom: 52px !important; }

.m-l-52 {
  margin-left: 52px !important; }

.p-53 {
  padding: 53px !important; }

.p-t-53 {
  padding-top: 53px !important; }

.p-r-53 {
  padding-right: 53px !important; }

.p-b-53 {
  padding-bottom: 53px !important; }

.p-l-53 {
  padding-left: 53px !important; }

.m-53 {
  margin: 53px !important; }

.m-t-53 {
  margin-top: 53px !important; }

.m-r-53 {
  margin-right: 53px !important; }

.m-b-53 {
  margin-bottom: 53px !important; }

.m-l-53 {
  margin-left: 53px !important; }

.p-54 {
  padding: 54px !important; }

.p-t-54 {
  padding-top: 54px !important; }

.p-r-54 {
  padding-right: 54px !important; }

.p-b-54 {
  padding-bottom: 54px !important; }

.p-l-54 {
  padding-left: 54px !important; }

.m-54 {
  margin: 54px !important; }

.m-t-54 {
  margin-top: 54px !important; }

.m-r-54 {
  margin-right: 54px !important; }

.m-b-54 {
  margin-bottom: 54px !important; }

.m-l-54 {
  margin-left: 54px !important; }

.p-55 {
  padding: 55px !important; }

.p-t-55 {
  padding-top: 55px !important; }

.p-r-55 {
  padding-right: 55px !important; }

.p-b-55 {
  padding-bottom: 55px !important; }

.p-l-55 {
  padding-left: 55px !important; }

.m-55 {
  margin: 55px !important; }

.m-t-55 {
  margin-top: 55px !important; }

.m-r-55 {
  margin-right: 55px !important; }

.m-b-55 {
  margin-bottom: 55px !important; }

.m-l-55 {
  margin-left: 55px !important; }

.p-56 {
  padding: 56px !important; }

.p-t-56 {
  padding-top: 56px !important; }

.p-r-56 {
  padding-right: 56px !important; }

.p-b-56 {
  padding-bottom: 56px !important; }

.p-l-56 {
  padding-left: 56px !important; }

.m-56 {
  margin: 56px !important; }

.m-t-56 {
  margin-top: 56px !important; }

.m-r-56 {
  margin-right: 56px !important; }

.m-b-56 {
  margin-bottom: 56px !important; }

.m-l-56 {
  margin-left: 56px !important; }

.p-57 {
  padding: 57px !important; }

.p-t-57 {
  padding-top: 57px !important; }

.p-r-57 {
  padding-right: 57px !important; }

.p-b-57 {
  padding-bottom: 57px !important; }

.p-l-57 {
  padding-left: 57px !important; }

.m-57 {
  margin: 57px !important; }

.m-t-57 {
  margin-top: 57px !important; }

.m-r-57 {
  margin-right: 57px !important; }

.m-b-57 {
  margin-bottom: 57px !important; }

.m-l-57 {
  margin-left: 57px !important; }

.p-58 {
  padding: 58px !important; }

.p-t-58 {
  padding-top: 58px !important; }

.p-r-58 {
  padding-right: 58px !important; }

.p-b-58 {
  padding-bottom: 58px !important; }

.p-l-58 {
  padding-left: 58px !important; }

.m-58 {
  margin: 58px !important; }

.m-t-58 {
  margin-top: 58px !important; }

.m-r-58 {
  margin-right: 58px !important; }

.m-b-58 {
  margin-bottom: 58px !important; }

.m-l-58 {
  margin-left: 58px !important; }

.p-59 {
  padding: 59px !important; }

.p-t-59 {
  padding-top: 59px !important; }

.p-r-59 {
  padding-right: 59px !important; }

.p-b-59 {
  padding-bottom: 59px !important; }

.p-l-59 {
  padding-left: 59px !important; }

.m-59 {
  margin: 59px !important; }

.m-t-59 {
  margin-top: 59px !important; }

.m-r-59 {
  margin-right: 59px !important; }

.m-b-59 {
  margin-bottom: 59px !important; }

.m-l-59 {
  margin-left: 59px !important; }

.p-60 {
  padding: 60px !important; }

.p-t-60 {
  padding-top: 60px !important; }

.p-r-60 {
  padding-right: 60px !important; }

.p-b-60 {
  padding-bottom: 60px !important; }

.p-l-60 {
  padding-left: 60px !important; }

.m-60 {
  margin: 60px !important; }

.m-t-60 {
  margin-top: 60px !important; }

.m-r-60 {
  margin-right: 60px !important; }

.m-b-60 {
  margin-bottom: 60px !important; }

.m-l-60 {
  margin-left: 60px !important; }

.p-61 {
  padding: 61px !important; }

.p-t-61 {
  padding-top: 61px !important; }

.p-r-61 {
  padding-right: 61px !important; }

.p-b-61 {
  padding-bottom: 61px !important; }

.p-l-61 {
  padding-left: 61px !important; }

.m-61 {
  margin: 61px !important; }

.m-t-61 {
  margin-top: 61px !important; }

.m-r-61 {
  margin-right: 61px !important; }

.m-b-61 {
  margin-bottom: 61px !important; }

.m-l-61 {
  margin-left: 61px !important; }

.p-62 {
  padding: 62px !important; }

.p-t-62 {
  padding-top: 62px !important; }

.p-r-62 {
  padding-right: 62px !important; }

.p-b-62 {
  padding-bottom: 62px !important; }

.p-l-62 {
  padding-left: 62px !important; }

.m-62 {
  margin: 62px !important; }

.m-t-62 {
  margin-top: 62px !important; }

.m-r-62 {
  margin-right: 62px !important; }

.m-b-62 {
  margin-bottom: 62px !important; }

.m-l-62 {
  margin-left: 62px !important; }

.p-63 {
  padding: 63px !important; }

.p-t-63 {
  padding-top: 63px !important; }

.p-r-63 {
  padding-right: 63px !important; }

.p-b-63 {
  padding-bottom: 63px !important; }

.p-l-63 {
  padding-left: 63px !important; }

.m-63 {
  margin: 63px !important; }

.m-t-63 {
  margin-top: 63px !important; }

.m-r-63 {
  margin-right: 63px !important; }

.m-b-63 {
  margin-bottom: 63px !important; }

.m-l-63 {
  margin-left: 63px !important; }

.p-64 {
  padding: 64px !important; }

.p-t-64 {
  padding-top: 64px !important; }

.p-r-64 {
  padding-right: 64px !important; }

.p-b-64 {
  padding-bottom: 64px !important; }

.p-l-64 {
  padding-left: 64px !important; }

.m-64 {
  margin: 64px !important; }

.m-t-64 {
  margin-top: 64px !important; }

.m-r-64 {
  margin-right: 64px !important; }

.m-b-64 {
  margin-bottom: 64px !important; }

.m-l-64 {
  margin-left: 64px !important; }

.p-65 {
  padding: 65px !important; }

.p-t-65 {
  padding-top: 65px !important; }

.p-r-65 {
  padding-right: 65px !important; }

.p-b-65 {
  padding-bottom: 65px !important; }

.p-l-65 {
  padding-left: 65px !important; }

.m-65 {
  margin: 65px !important; }

.m-t-65 {
  margin-top: 65px !important; }

.m-r-65 {
  margin-right: 65px !important; }

.m-b-65 {
  margin-bottom: 65px !important; }

.m-l-65 {
  margin-left: 65px !important; }

.p-66 {
  padding: 66px !important; }

.p-t-66 {
  padding-top: 66px !important; }

.p-r-66 {
  padding-right: 66px !important; }

.p-b-66 {
  padding-bottom: 66px !important; }

.p-l-66 {
  padding-left: 66px !important; }

.m-66 {
  margin: 66px !important; }

.m-t-66 {
  margin-top: 66px !important; }

.m-r-66 {
  margin-right: 66px !important; }

.m-b-66 {
  margin-bottom: 66px !important; }

.m-l-66 {
  margin-left: 66px !important; }

.p-67 {
  padding: 67px !important; }

.p-t-67 {
  padding-top: 67px !important; }

.p-r-67 {
  padding-right: 67px !important; }

.p-b-67 {
  padding-bottom: 67px !important; }

.p-l-67 {
  padding-left: 67px !important; }

.m-67 {
  margin: 67px !important; }

.m-t-67 {
  margin-top: 67px !important; }

.m-r-67 {
  margin-right: 67px !important; }

.m-b-67 {
  margin-bottom: 67px !important; }

.m-l-67 {
  margin-left: 67px !important; }

.p-68 {
  padding: 68px !important; }

.p-t-68 {
  padding-top: 68px !important; }

.p-r-68 {
  padding-right: 68px !important; }

.p-b-68 {
  padding-bottom: 68px !important; }

.p-l-68 {
  padding-left: 68px !important; }

.m-68 {
  margin: 68px !important; }

.m-t-68 {
  margin-top: 68px !important; }

.m-r-68 {
  margin-right: 68px !important; }

.m-b-68 {
  margin-bottom: 68px !important; }

.m-l-68 {
  margin-left: 68px !important; }

.p-69 {
  padding: 69px !important; }

.p-t-69 {
  padding-top: 69px !important; }

.p-r-69 {
  padding-right: 69px !important; }

.p-b-69 {
  padding-bottom: 69px !important; }

.p-l-69 {
  padding-left: 69px !important; }

.m-69 {
  margin: 69px !important; }

.m-t-69 {
  margin-top: 69px !important; }

.m-r-69 {
  margin-right: 69px !important; }

.m-b-69 {
  margin-bottom: 69px !important; }

.m-l-69 {
  margin-left: 69px !important; }

.p-70 {
  padding: 70px !important; }

.p-t-70 {
  padding-top: 70px !important; }

.p-r-70 {
  padding-right: 70px !important; }

.p-b-70 {
  padding-bottom: 70px !important; }

.p-l-70 {
  padding-left: 70px !important; }

.m-70 {
  margin: 70px !important; }

.m-t-70 {
  margin-top: 70px !important; }

.m-r-70 {
  margin-right: 70px !important; }

.m-b-70 {
  margin-bottom: 70px !important; }

.m-l-70 {
  margin-left: 70px !important; }

.p-71 {
  padding: 71px !important; }

.p-t-71 {
  padding-top: 71px !important; }

.p-r-71 {
  padding-right: 71px !important; }

.p-b-71 {
  padding-bottom: 71px !important; }

.p-l-71 {
  padding-left: 71px !important; }

.m-71 {
  margin: 71px !important; }

.m-t-71 {
  margin-top: 71px !important; }

.m-r-71 {
  margin-right: 71px !important; }

.m-b-71 {
  margin-bottom: 71px !important; }

.m-l-71 {
  margin-left: 71px !important; }

.p-72 {
  padding: 72px !important; }

.p-t-72 {
  padding-top: 72px !important; }

.p-r-72 {
  padding-right: 72px !important; }

.p-b-72 {
  padding-bottom: 72px !important; }

.p-l-72 {
  padding-left: 72px !important; }

.m-72 {
  margin: 72px !important; }

.m-t-72 {
  margin-top: 72px !important; }

.m-r-72 {
  margin-right: 72px !important; }

.m-b-72 {
  margin-bottom: 72px !important; }

.m-l-72 {
  margin-left: 72px !important; }

.p-73 {
  padding: 73px !important; }

.p-t-73 {
  padding-top: 73px !important; }

.p-r-73 {
  padding-right: 73px !important; }

.p-b-73 {
  padding-bottom: 73px !important; }

.p-l-73 {
  padding-left: 73px !important; }

.m-73 {
  margin: 73px !important; }

.m-t-73 {
  margin-top: 73px !important; }

.m-r-73 {
  margin-right: 73px !important; }

.m-b-73 {
  margin-bottom: 73px !important; }

.m-l-73 {
  margin-left: 73px !important; }

.p-74 {
  padding: 74px !important; }

.p-t-74 {
  padding-top: 74px !important; }

.p-r-74 {
  padding-right: 74px !important; }

.p-b-74 {
  padding-bottom: 74px !important; }

.p-l-74 {
  padding-left: 74px !important; }

.m-74 {
  margin: 74px !important; }

.m-t-74 {
  margin-top: 74px !important; }

.m-r-74 {
  margin-right: 74px !important; }

.m-b-74 {
  margin-bottom: 74px !important; }

.m-l-74 {
  margin-left: 74px !important; }

.p-75 {
  padding: 75px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.m-75 {
  margin: 75px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.p-76 {
  padding: 76px !important; }

.p-t-76 {
  padding-top: 76px !important; }

.p-r-76 {
  padding-right: 76px !important; }

.p-b-76 {
  padding-bottom: 76px !important; }

.p-l-76 {
  padding-left: 76px !important; }

.m-76 {
  margin: 76px !important; }

.m-t-76 {
  margin-top: 76px !important; }

.m-r-76 {
  margin-right: 76px !important; }

.m-b-76 {
  margin-bottom: 76px !important; }

.m-l-76 {
  margin-left: 76px !important; }

.p-77 {
  padding: 77px !important; }

.p-t-77 {
  padding-top: 77px !important; }

.p-r-77 {
  padding-right: 77px !important; }

.p-b-77 {
  padding-bottom: 77px !important; }

.p-l-77 {
  padding-left: 77px !important; }

.m-77 {
  margin: 77px !important; }

.m-t-77 {
  margin-top: 77px !important; }

.m-r-77 {
  margin-right: 77px !important; }

.m-b-77 {
  margin-bottom: 77px !important; }

.m-l-77 {
  margin-left: 77px !important; }

.p-78 {
  padding: 78px !important; }

.p-t-78 {
  padding-top: 78px !important; }

.p-r-78 {
  padding-right: 78px !important; }

.p-b-78 {
  padding-bottom: 78px !important; }

.p-l-78 {
  padding-left: 78px !important; }

.m-78 {
  margin: 78px !important; }

.m-t-78 {
  margin-top: 78px !important; }

.m-r-78 {
  margin-right: 78px !important; }

.m-b-78 {
  margin-bottom: 78px !important; }

.m-l-78 {
  margin-left: 78px !important; }

.p-79 {
  padding: 79px !important; }

.p-t-79 {
  padding-top: 79px !important; }

.p-r-79 {
  padding-right: 79px !important; }

.p-b-79 {
  padding-bottom: 79px !important; }

.p-l-79 {
  padding-left: 79px !important; }

.m-79 {
  margin: 79px !important; }

.m-t-79 {
  margin-top: 79px !important; }

.m-r-79 {
  margin-right: 79px !important; }

.m-b-79 {
  margin-bottom: 79px !important; }

.m-l-79 {
  margin-left: 79px !important; }

.p-80 {
  padding: 80px !important; }

.p-t-80 {
  padding-top: 80px !important; }

.p-r-80 {
  padding-right: 80px !important; }

.p-b-80 {
  padding-bottom: 80px !important; }

.p-l-80 {
  padding-left: 80px !important; }

.m-80 {
  margin: 80px !important; }

.m-t-80 {
  margin-top: 80px !important; }

.m-r-80 {
  margin-right: 80px !important; }

.m-b-80 {
  margin-bottom: 80px !important; }

.m-l-80 {
  margin-left: 80px !important; }

.p-81 {
  padding: 81px !important; }

.p-t-81 {
  padding-top: 81px !important; }

.p-r-81 {
  padding-right: 81px !important; }

.p-b-81 {
  padding-bottom: 81px !important; }

.p-l-81 {
  padding-left: 81px !important; }

.m-81 {
  margin: 81px !important; }

.m-t-81 {
  margin-top: 81px !important; }

.m-r-81 {
  margin-right: 81px !important; }

.m-b-81 {
  margin-bottom: 81px !important; }

.m-l-81 {
  margin-left: 81px !important; }

.p-82 {
  padding: 82px !important; }

.p-t-82 {
  padding-top: 82px !important; }

.p-r-82 {
  padding-right: 82px !important; }

.p-b-82 {
  padding-bottom: 82px !important; }

.p-l-82 {
  padding-left: 82px !important; }

.m-82 {
  margin: 82px !important; }

.m-t-82 {
  margin-top: 82px !important; }

.m-r-82 {
  margin-right: 82px !important; }

.m-b-82 {
  margin-bottom: 82px !important; }

.m-l-82 {
  margin-left: 82px !important; }

.p-83 {
  padding: 83px !important; }

.p-t-83 {
  padding-top: 83px !important; }

.p-r-83 {
  padding-right: 83px !important; }

.p-b-83 {
  padding-bottom: 83px !important; }

.p-l-83 {
  padding-left: 83px !important; }

.m-83 {
  margin: 83px !important; }

.m-t-83 {
  margin-top: 83px !important; }

.m-r-83 {
  margin-right: 83px !important; }

.m-b-83 {
  margin-bottom: 83px !important; }

.m-l-83 {
  margin-left: 83px !important; }

.p-84 {
  padding: 84px !important; }

.p-t-84 {
  padding-top: 84px !important; }

.p-r-84 {
  padding-right: 84px !important; }

.p-b-84 {
  padding-bottom: 84px !important; }

.p-l-84 {
  padding-left: 84px !important; }

.m-84 {
  margin: 84px !important; }

.m-t-84 {
  margin-top: 84px !important; }

.m-r-84 {
  margin-right: 84px !important; }

.m-b-84 {
  margin-bottom: 84px !important; }

.m-l-84 {
  margin-left: 84px !important; }

.p-85 {
  padding: 85px !important; }

.p-t-85 {
  padding-top: 85px !important; }

.p-r-85 {
  padding-right: 85px !important; }

.p-b-85 {
  padding-bottom: 85px !important; }

.p-l-85 {
  padding-left: 85px !important; }

.m-85 {
  margin: 85px !important; }

.m-t-85 {
  margin-top: 85px !important; }

.m-r-85 {
  margin-right: 85px !important; }

.m-b-85 {
  margin-bottom: 85px !important; }

.m-l-85 {
  margin-left: 85px !important; }

.p-86 {
  padding: 86px !important; }

.p-t-86 {
  padding-top: 86px !important; }

.p-r-86 {
  padding-right: 86px !important; }

.p-b-86 {
  padding-bottom: 86px !important; }

.p-l-86 {
  padding-left: 86px !important; }

.m-86 {
  margin: 86px !important; }

.m-t-86 {
  margin-top: 86px !important; }

.m-r-86 {
  margin-right: 86px !important; }

.m-b-86 {
  margin-bottom: 86px !important; }

.m-l-86 {
  margin-left: 86px !important; }

.p-87 {
  padding: 87px !important; }

.p-t-87 {
  padding-top: 87px !important; }

.p-r-87 {
  padding-right: 87px !important; }

.p-b-87 {
  padding-bottom: 87px !important; }

.p-l-87 {
  padding-left: 87px !important; }

.m-87 {
  margin: 87px !important; }

.m-t-87 {
  margin-top: 87px !important; }

.m-r-87 {
  margin-right: 87px !important; }

.m-b-87 {
  margin-bottom: 87px !important; }

.m-l-87 {
  margin-left: 87px !important; }

.p-88 {
  padding: 88px !important; }

.p-t-88 {
  padding-top: 88px !important; }

.p-r-88 {
  padding-right: 88px !important; }

.p-b-88 {
  padding-bottom: 88px !important; }

.p-l-88 {
  padding-left: 88px !important; }

.m-88 {
  margin: 88px !important; }

.m-t-88 {
  margin-top: 88px !important; }

.m-r-88 {
  margin-right: 88px !important; }

.m-b-88 {
  margin-bottom: 88px !important; }

.m-l-88 {
  margin-left: 88px !important; }

.p-89 {
  padding: 89px !important; }

.p-t-89 {
  padding-top: 89px !important; }

.p-r-89 {
  padding-right: 89px !important; }

.p-b-89 {
  padding-bottom: 89px !important; }

.p-l-89 {
  padding-left: 89px !important; }

.m-89 {
  margin: 89px !important; }

.m-t-89 {
  margin-top: 89px !important; }

.m-r-89 {
  margin-right: 89px !important; }

.m-b-89 {
  margin-bottom: 89px !important; }

.m-l-89 {
  margin-left: 89px !important; }

.p-90 {
  padding: 90px !important; }

.p-t-90 {
  padding-top: 90px !important; }

.p-r-90 {
  padding-right: 90px !important; }

.p-b-90 {
  padding-bottom: 90px !important; }

.p-l-90 {
  padding-left: 90px !important; }

.m-90 {
  margin: 90px !important; }

.m-t-90 {
  margin-top: 90px !important; }

.m-r-90 {
  margin-right: 90px !important; }

.m-b-90 {
  margin-bottom: 90px !important; }

.m-l-90 {
  margin-left: 90px !important; }

.p-91 {
  padding: 91px !important; }

.p-t-91 {
  padding-top: 91px !important; }

.p-r-91 {
  padding-right: 91px !important; }

.p-b-91 {
  padding-bottom: 91px !important; }

.p-l-91 {
  padding-left: 91px !important; }

.m-91 {
  margin: 91px !important; }

.m-t-91 {
  margin-top: 91px !important; }

.m-r-91 {
  margin-right: 91px !important; }

.m-b-91 {
  margin-bottom: 91px !important; }

.m-l-91 {
  margin-left: 91px !important; }

.p-92 {
  padding: 92px !important; }

.p-t-92 {
  padding-top: 92px !important; }

.p-r-92 {
  padding-right: 92px !important; }

.p-b-92 {
  padding-bottom: 92px !important; }

.p-l-92 {
  padding-left: 92px !important; }

.m-92 {
  margin: 92px !important; }

.m-t-92 {
  margin-top: 92px !important; }

.m-r-92 {
  margin-right: 92px !important; }

.m-b-92 {
  margin-bottom: 92px !important; }

.m-l-92 {
  margin-left: 92px !important; }

.p-93 {
  padding: 93px !important; }

.p-t-93 {
  padding-top: 93px !important; }

.p-r-93 {
  padding-right: 93px !important; }

.p-b-93 {
  padding-bottom: 93px !important; }

.p-l-93 {
  padding-left: 93px !important; }

.m-93 {
  margin: 93px !important; }

.m-t-93 {
  margin-top: 93px !important; }

.m-r-93 {
  margin-right: 93px !important; }

.m-b-93 {
  margin-bottom: 93px !important; }

.m-l-93 {
  margin-left: 93px !important; }

.p-94 {
  padding: 94px !important; }

.p-t-94 {
  padding-top: 94px !important; }

.p-r-94 {
  padding-right: 94px !important; }

.p-b-94 {
  padding-bottom: 94px !important; }

.p-l-94 {
  padding-left: 94px !important; }

.m-94 {
  margin: 94px !important; }

.m-t-94 {
  margin-top: 94px !important; }

.m-r-94 {
  margin-right: 94px !important; }

.m-b-94 {
  margin-bottom: 94px !important; }

.m-l-94 {
  margin-left: 94px !important; }

.p-95 {
  padding: 95px !important; }

.p-t-95 {
  padding-top: 95px !important; }

.p-r-95 {
  padding-right: 95px !important; }

.p-b-95 {
  padding-bottom: 95px !important; }

.p-l-95 {
  padding-left: 95px !important; }

.m-95 {
  margin: 95px !important; }

.m-t-95 {
  margin-top: 95px !important; }

.m-r-95 {
  margin-right: 95px !important; }

.m-b-95 {
  margin-bottom: 95px !important; }

.m-l-95 {
  margin-left: 95px !important; }

.p-96 {
  padding: 96px !important; }

.p-t-96 {
  padding-top: 96px !important; }

.p-r-96 {
  padding-right: 96px !important; }

.p-b-96 {
  padding-bottom: 96px !important; }

.p-l-96 {
  padding-left: 96px !important; }

.m-96 {
  margin: 96px !important; }

.m-t-96 {
  margin-top: 96px !important; }

.m-r-96 {
  margin-right: 96px !important; }

.m-b-96 {
  margin-bottom: 96px !important; }

.m-l-96 {
  margin-left: 96px !important; }

.p-97 {
  padding: 97px !important; }

.p-t-97 {
  padding-top: 97px !important; }

.p-r-97 {
  padding-right: 97px !important; }

.p-b-97 {
  padding-bottom: 97px !important; }

.p-l-97 {
  padding-left: 97px !important; }

.m-97 {
  margin: 97px !important; }

.m-t-97 {
  margin-top: 97px !important; }

.m-r-97 {
  margin-right: 97px !important; }

.m-b-97 {
  margin-bottom: 97px !important; }

.m-l-97 {
  margin-left: 97px !important; }

.p-98 {
  padding: 98px !important; }

.p-t-98 {
  padding-top: 98px !important; }

.p-r-98 {
  padding-right: 98px !important; }

.p-b-98 {
  padding-bottom: 98px !important; }

.p-l-98 {
  padding-left: 98px !important; }

.m-98 {
  margin: 98px !important; }

.m-t-98 {
  margin-top: 98px !important; }

.m-r-98 {
  margin-right: 98px !important; }

.m-b-98 {
  margin-bottom: 98px !important; }

.m-l-98 {
  margin-left: 98px !important; }

.p-99 {
  padding: 99px !important; }

.p-t-99 {
  padding-top: 99px !important; }

.p-r-99 {
  padding-right: 99px !important; }

.p-b-99 {
  padding-bottom: 99px !important; }

.p-l-99 {
  padding-left: 99px !important; }

.m-99 {
  margin: 99px !important; }

.m-t-99 {
  margin-top: 99px !important; }

.m-r-99 {
  margin-right: 99px !important; }

.m-b-99 {
  margin-bottom: 99px !important; }

.m-l-99 {
  margin-left: 99px !important; }

.p-100 {
  padding: 100px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.m-100 {
  margin: 100px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-white {
  color: #fff; }

.border-round {
  border-radius: 5px; }

.align-items-center {
  align-items: center; }

.justify-content-center {
  justify-content: center; }

.justify-content-between {
  justify-content: space-between; }

.ant-select-dropdown {
  z-index: 2500; }

.ant-notification {
  z-index: 3000; }

.ant-tooltip {
  z-index: 3500; }

.w-100 {
  width: 100%; }

.sticky-card {
  position: sticky;
  position: -webkit-sticky;
  top: 0; }

.display-contents {
  display: contents; }

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.table-border-round {
  background: none !important; }
  .table-border-round table {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden; }
    .table-border-round table thead tr th {
      background: #eeeeee;
      color: #4a4a4a;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase; }
      .table-border-round table thead tr th:first-child {
        padding-left: 15px !important; }
      .table-border-round table thead tr th:last-child {
        padding-right: 15px !important; }
    .table-border-round table td {
      background: #fff; }
      .table-border-round table td:first-child {
        padding-left: 15px !important; }
      .table-border-round table td:last-child {
        padding-right: 15px !important; }

.link:hover {
  cursor: default; }

.z-index-1000 {
  z-index: 1000; }

.has-error.has-feedback .ant-form-item-children-icon {
  margin-top: 10px; }

.ant-checkbox-inner {
  border-color: #abd7ff !important; }

.break-word {
  word-break: break-word; }

.mix-blend-difference {
  mix-blend-mode: difference; }

.input-text-label,
.date-time-picker-label {
  color: rgba(0, 0, 0, 0.8) !important; }

.ant-form-item-label {
  line-height: 36px;
  margin-bottom: -8px; }
  .ant-form-item-label .ant-form-item-no-colon {
    color: rgba(0, 0, 0, 0.8) !important; }

.theme-default-light .btn-primary {
  background-color: #3952a1;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-default-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-default-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-default-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-default-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-default-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-default-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-default-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-default-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-default-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-default-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-default-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-default-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-default-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-default-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-default-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-default-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-default-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-default-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-default-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-default-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-default-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-default-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-default-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-default-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-default-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-default-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-default-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-default-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-default-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-default-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-default-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-default-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-default-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-default-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-default-dark .btn-primary {
  background-color: transparent;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-default-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-default-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-default-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-default-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-default-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-default-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-default-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-default-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-default-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-default-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-default-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-default-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-default-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-default-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-default-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-default-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-default-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-default-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-default-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-default-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-default-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-default-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-default-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-default-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-default-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-default-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-default-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-default-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-default-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-default-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-default-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-default-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-default-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-default-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-exam .btn-primary {
  background-color: #6f0766;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-exam .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-exam .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-exam .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-exam .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-exam .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-exam .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-exam .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-exam .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-exam .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-exam .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-exam .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-exam .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-exam .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-exam .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-exam .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-exam .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-exam .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-exam .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-exam .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-exam .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-exam .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-exam .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-exam .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-exam .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-exam .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-exam .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-exam .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-exam .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-exam .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-exam .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-exam .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-exam .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-exam .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-exam .btn-primary:hover {
    border-color: #6f0766; }

.theme-sb-light .btn-primary {
  background-color: #3952A1;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-sb-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-sb-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-sb-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-sb-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-sb-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-sb-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-sb-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-sb-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-sb-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-sb-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-sb-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-sb-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-sb-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-sb-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-sb-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-sb-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-sb-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-sb-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-sb-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-sb-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-sb-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-sb-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-sb-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-sb-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-sb-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-sb-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-sb-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-sb-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-sb-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-sb-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-sb-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-sb-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-sb-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-sb-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-sb-dark .btn-primary {
  background-color: transparent;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-sb-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-sb-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-sb-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-sb-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-sb-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-sb-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-sb-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-sb-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-sb-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-sb-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-sb-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-sb-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-sb-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-sb-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-sb-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-sb-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-sb-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-sb-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-sb-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-sb-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-sb-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-sb-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-sb-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-sb-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-sb-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-sb-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-sb-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-sb-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-sb-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-sb-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-sb-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-sb-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-sb-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-sb-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-evn-light .btn-primary {
  background-color: #3952A1;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-evn-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-evn-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-evn-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-evn-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-evn-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-evn-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-evn-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-evn-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-evn-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-evn-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-evn-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-evn-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-evn-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-evn-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-evn-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-evn-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-evn-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-evn-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-evn-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-evn-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-evn-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-evn-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-evn-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-evn-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-evn-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-evn-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-evn-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-evn-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-evn-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-evn-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-evn-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-evn-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-evn-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-evn-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-evn-dark .btn-primary {
  background-color: transparent;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-evn-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-evn-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-evn-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-evn-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-evn-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-evn-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-evn-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-evn-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-evn-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-evn-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-evn-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-evn-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-evn-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-evn-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-evn-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-evn-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-evn-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-evn-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-evn-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-evn-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-evn-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-evn-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-evn-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-evn-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-evn-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-evn-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-evn-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-evn-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-evn-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-evn-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-evn-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-evn-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-evn-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-evn-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-msi-light .btn-primary {
  background-color: #3952A1;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-msi-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-msi-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-msi-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-msi-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-msi-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-msi-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-msi-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-msi-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-msi-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-msi-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-msi-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-msi-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-msi-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-msi-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-msi-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-msi-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-msi-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-msi-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-msi-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-msi-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-msi-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-msi-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-msi-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-msi-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-msi-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-msi-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-msi-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-msi-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-msi-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-msi-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-msi-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-msi-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-msi-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-msi-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-msi-dark .btn-primary {
  background-color: transparent;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-msi-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-msi-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-msi-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-msi-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-msi-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-msi-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-msi-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-msi-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-msi-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-msi-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-msi-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-msi-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-msi-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-msi-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-msi-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-msi-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-msi-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-msi-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-msi-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-msi-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-msi-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-msi-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-msi-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-msi-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-msi-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-msi-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-msi-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-msi-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-msi-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-msi-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-msi-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-msi-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-msi-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-msi-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-vt-light .btn-primary {
  background-color: #00918d;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-vt-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-vt-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-vt-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-vt-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-vt-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-vt-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-vt-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-vt-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-vt-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-vt-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-vt-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-vt-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-vt-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-vt-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-vt-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-vt-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-vt-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-vt-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-vt-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-vt-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-vt-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-vt-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-vt-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-vt-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-vt-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-vt-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-vt-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-vt-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-vt-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-vt-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-vt-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-vt-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-vt-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-vt-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-vt-dark .btn-primary {
  background-color: transparent;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-vt-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-vt-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-vt-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-vt-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-vt-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-vt-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-vt-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-vt-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-vt-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-vt-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-vt-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-vt-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-vt-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-vt-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-vt-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-vt-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-vt-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-vt-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-vt-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-vt-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-vt-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-vt-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-vt-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-vt-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-vt-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-vt-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-vt-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-vt-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-vt-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-vt-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-vt-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-vt-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-vt-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-vt-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-blue-light .btn-primary {
  background-color: #3790cf;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-blue-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-blue-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-blue-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-blue-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-blue-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-blue-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-blue-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-blue-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-blue-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-blue-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-blue-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-blue-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-blue-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-blue-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-blue-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-blue-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-blue-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-blue-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-blue-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-blue-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-blue-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-blue-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-blue-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-blue-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-blue-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-blue-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-blue-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-blue-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-blue-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-blue-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-blue-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-blue-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-blue-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-blue-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-blue-dark .btn-primary {
  background-color: #3790cf;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-blue-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-blue-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-blue-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-blue-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-blue-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-blue-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-blue-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-blue-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-blue-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-blue-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-blue-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-blue-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-blue-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-blue-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-blue-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-blue-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-blue-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-blue-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-blue-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-blue-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-blue-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-blue-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-blue-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-blue-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-blue-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-blue-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-blue-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-blue-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-blue-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-blue-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-blue-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-blue-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-blue-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-blue-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-hpu2-light .btn-primary {
  background-color: #3952A1;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-hpu2-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-hpu2-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-hpu2-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-hpu2-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-hpu2-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-hpu2-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-hpu2-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-hpu2-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-hpu2-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-hpu2-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-hpu2-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-hpu2-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-hpu2-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-hpu2-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-hpu2-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-hpu2-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-hpu2-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-hpu2-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-hpu2-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-hpu2-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-hpu2-dark .btn-primary {
  background-color: transparent;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-hpu2-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-hpu2-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-hpu2-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-hpu2-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-hpu2-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-hpu2-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-gj-light .btn-primary {
  background-color: #6f0766;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-gj-light .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-gj-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-gj-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-gj-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-gj-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-gj-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-gj-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-gj-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-gj-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-gj-light .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-gj-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-gj-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-gj-light .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-gj-light .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-gj-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-gj-light .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-gj-light .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-gj-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-gj-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-gj-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-gj-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-gj-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-gj-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-gj-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-gj-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-gj-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-gj-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-gj-light .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-gj-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-gj-light .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-gj-light .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-gj-light .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-gj-light .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-gj-light .btn-primary:hover {
    border-color: #6f0766; }

.theme-gj-dark .btn-primary {
  background-color: transparent;
  color: #92c36a;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-gj-dark .btn-primary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-gj-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-gj-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-gj-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-gj-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-gj-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-gj-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-gj-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-gj-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-gj-dark .btn-primary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-gj-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-gj-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-gj-dark .btn-primary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-gj-dark .btn-primary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-gj-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-gj-dark .btn-primary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-gj-dark .btn-primary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-gj-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-gj-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-gj-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-gj-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-gj-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-gj-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-gj-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-gj-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-gj-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-gj-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-gj-dark .btn-primary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-gj-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-gj-dark .btn-primary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-gj-dark .btn-primary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-gj-dark .btn-primary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-gj-dark .btn-primary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-gj-dark .btn-primary:hover {
    border-color: #6f0766; }

.theme-default-light .btn-primary:hover {
  background-color: #2c3972;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-default-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-default-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-default-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-default-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-default-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-default-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-default-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-default-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-default-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-default-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-default-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-default-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-default-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-default-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-default-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-default-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-default-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-default-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-default-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-default-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-default-dark .btn-primary:hover {
  background-color: #92c36a;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-default-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-default-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-default-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-default-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-default-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-default-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-exam .btn-primary:hover {
  background-color: #6f0766;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-exam .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-exam .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-exam .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-exam .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-exam .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-exam .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-exam .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-exam .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-exam .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-exam .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-exam .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-exam .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-exam .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-exam .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-exam .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-exam .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-exam .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-exam .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-exam .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-exam .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-exam .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-exam .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-exam .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-exam .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-exam .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-exam .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-exam .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-exam .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-exam .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-exam .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-exam .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-exam .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-exam .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-exam .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-sb-light .btn-primary:hover {
  background-color: #2c3972;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-sb-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-sb-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-sb-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-sb-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-sb-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-sb-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-sb-dark .btn-primary:hover {
  background-color: #92c36a;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-sb-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-sb-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-sb-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-sb-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-sb-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-sb-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-evn-light .btn-primary:hover {
  background-color: #2c3972;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-evn-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-evn-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-evn-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-evn-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-evn-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-evn-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-evn-dark .btn-primary:hover {
  background-color: #92c36a;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-evn-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-evn-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-evn-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-evn-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-evn-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-evn-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-msi-light .btn-primary:hover {
  background-color: #2c3972;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-msi-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-msi-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-msi-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-msi-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-msi-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-msi-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-msi-dark .btn-primary:hover {
  background-color: #92c36a;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-msi-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-msi-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-msi-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-msi-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-msi-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-msi-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-vt-light .btn-primary:hover {
  background-color: white;
  color: #00918d;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-vt-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-vt-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-vt-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-vt-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-vt-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-vt-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-vt-dark .btn-primary:hover {
  background-color: white;
  color: #00918d;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-vt-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-vt-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-vt-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-vt-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-vt-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-vt-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-blue-light .btn-primary:hover {
  background-color: white;
  color: #3790cf;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-blue-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-blue-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-blue-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-blue-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-blue-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-blue-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-blue-dark .btn-primary:hover {
  background-color: white;
  color: #3790cf;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-blue-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-blue-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-blue-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-blue-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-blue-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-blue-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-hpu2-light .btn-primary:hover {
  background-color: #2c3972;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-hpu2-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-hpu2-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-hpu2-dark .btn-primary:hover {
  background-color: #92c36a;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-hpu2-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-hpu2-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-gj-light .btn-primary:hover {
  background-color: #6f0766;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-gj-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-gj-light .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-gj-light .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-gj-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-gj-light .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-gj-light .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-gj-dark .btn-primary:hover {
  background-color: #92c36a;
  color: white;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-gj-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-gj-dark .btn-primary:hover {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-gj-dark .btn-primary:hover {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-exam .theme-gj-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-gj-dark .btn-primary:hover:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-gj-dark .btn-primary:hover:hover {
    border-color: #6f0766; }

.theme-default-light .btn-secondary {
  background-color: white;
  color: #3952a1;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-default-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-default-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-default-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-default-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-default-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-default-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-default-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-default-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-default-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-default-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-default-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-default-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-default-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-default-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-default-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-default-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-default-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-default-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-default-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-default-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-default-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-default-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-default-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-default-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-default-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-default-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-default-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-default-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-default-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-default-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-default-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-default-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-default-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-default-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-default-dark .btn-secondary {
  background-color: #92c36a;
  color: transparent;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-default-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-default-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-default-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-default-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-default-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-default-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-default-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-default-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-default-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-default-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-default-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-default-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-default-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-default-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-default-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-default-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-default-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-default-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-default-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-default-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-default-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-default-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-default-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-default-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-default-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-default-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-default-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-default-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-default-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-default-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-default-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-default-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-default-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-default-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-exam .btn-secondary {
  background-color: white;
  color: #6f0766;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-exam .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-exam .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-exam .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-exam .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-exam .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-exam .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-exam .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-exam .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-exam .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-exam .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-exam .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-exam .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-exam .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-exam .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-exam .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-exam .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-exam .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-exam .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-exam .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-exam .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-exam .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-exam .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-exam .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-exam .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-exam .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-exam .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-exam .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-exam .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-exam .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-exam .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-exam .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-exam .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-exam .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-exam .btn-secondary:hover {
    border-color: #6f0766; }

.theme-sb-light .btn-secondary {
  background-color: white;
  color: #3952A1;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-sb-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-sb-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-sb-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-sb-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-sb-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-sb-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-sb-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-sb-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-sb-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-sb-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-sb-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-sb-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-sb-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-sb-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-sb-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-sb-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-sb-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-sb-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-sb-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-sb-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-sb-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-sb-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-sb-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-sb-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-sb-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-sb-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-sb-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-sb-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-sb-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-sb-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-sb-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-sb-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-sb-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-sb-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-sb-dark .btn-secondary {
  background-color: #92c36a;
  color: transparent;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-sb-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-sb-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-sb-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-sb-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-sb-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-sb-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-sb-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-sb-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-sb-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-sb-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-sb-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-sb-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-sb-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-sb-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-sb-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-sb-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-sb-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-sb-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-sb-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-sb-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-evn-light .btn-secondary {
  background-color: white;
  color: #3952A1;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-evn-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-evn-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-evn-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-evn-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-evn-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-evn-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-evn-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-evn-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-evn-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-evn-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-evn-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-evn-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-evn-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-evn-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-evn-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-evn-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-evn-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-evn-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-evn-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-evn-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-evn-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-evn-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-evn-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-evn-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-evn-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-evn-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-evn-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-evn-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-evn-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-evn-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-evn-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-evn-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-evn-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-evn-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-evn-dark .btn-secondary {
  background-color: #92c36a;
  color: transparent;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-evn-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-evn-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-evn-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-evn-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-evn-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-evn-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-evn-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-evn-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-evn-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-evn-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-evn-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-evn-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-evn-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-evn-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-evn-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-evn-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-evn-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-evn-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-evn-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-evn-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-msi-light .btn-secondary {
  background-color: white;
  color: #3952A1;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-msi-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-msi-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-msi-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-msi-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-msi-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-msi-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-msi-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-msi-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-msi-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-msi-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-msi-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-msi-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-msi-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-msi-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-msi-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-msi-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-msi-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-msi-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-msi-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-msi-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-msi-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-msi-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-msi-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-msi-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-msi-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-msi-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-msi-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-msi-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-msi-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-msi-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-msi-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-msi-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-msi-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-msi-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-msi-dark .btn-secondary {
  background-color: #92c36a;
  color: transparent;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-msi-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-msi-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-msi-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-msi-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-msi-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-msi-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-msi-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-msi-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-msi-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-msi-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-msi-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-msi-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-msi-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-msi-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-msi-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-msi-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-msi-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-msi-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-msi-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-msi-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-vt-light .btn-secondary {
  background-color: white;
  color: #00918d;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-vt-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-vt-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-vt-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-vt-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-vt-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-vt-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-vt-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-vt-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-vt-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-vt-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-vt-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-vt-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-vt-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-vt-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-vt-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-vt-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-vt-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-vt-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-vt-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-vt-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-vt-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-vt-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-vt-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-vt-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-vt-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-vt-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-vt-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-vt-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-vt-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-vt-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-vt-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-vt-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-vt-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-vt-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-vt-dark .btn-secondary {
  background-color: #92c36a;
  color: transparent;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-vt-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-vt-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-vt-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-vt-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-vt-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-vt-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-vt-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-vt-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-vt-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-vt-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-vt-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-vt-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-vt-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-vt-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-vt-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-vt-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-vt-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-vt-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-vt-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-vt-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-blue-light .btn-secondary {
  background-color: white;
  color: #3790cf;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-blue-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-blue-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-blue-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-blue-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-blue-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-blue-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-blue-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-blue-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-blue-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-blue-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-blue-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-blue-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-blue-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-blue-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-blue-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-blue-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-blue-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-blue-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-blue-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-blue-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-blue-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-blue-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-blue-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-blue-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-blue-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-blue-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-blue-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-blue-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-blue-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-blue-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-blue-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-blue-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-blue-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-blue-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-blue-dark .btn-secondary {
  background-color: #92c36a;
  color: #3790cf;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-blue-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-blue-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-blue-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-blue-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-blue-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-blue-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-blue-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-blue-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-blue-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-blue-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-blue-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-blue-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-blue-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-blue-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-blue-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-blue-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-blue-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-blue-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-blue-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-blue-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-hpu2-light .btn-secondary {
  background-color: white;
  color: #3952A1;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-hpu2-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-hpu2-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-hpu2-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-hpu2-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-hpu2-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-hpu2-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-hpu2-dark .btn-secondary {
  background-color: #92c36a;
  color: transparent;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-hpu2-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-hpu2-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-hpu2-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-hpu2-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-hpu2-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-hpu2-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-gj-light .btn-secondary {
  background-color: white;
  color: #6f0766;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-gj-light .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-gj-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-gj-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-gj-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-gj-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-gj-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-gj-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-gj-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-gj-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-gj-light .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-gj-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-gj-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-gj-light .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-gj-light .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-gj-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-gj-light .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-gj-light .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-gj-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-gj-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-gj-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-gj-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-gj-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-gj-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-gj-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-gj-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-gj-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-gj-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-gj-light .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-gj-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-gj-light .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-gj-light .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-gj-light .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-gj-light .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-gj-light .btn-secondary:hover {
    border-color: #6f0766; }

.theme-gj-dark .btn-secondary {
  background-color: #92c36a;
  color: transparent;
  outline: none;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  .theme-default-light .theme-gj-dark .btn-secondary {
    border: solid 1px #3952a1;
    border-radius: 2px; }
  .theme-default-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-exam .theme-gj-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-sb-light .theme-gj-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-sb-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-evn-light .theme-gj-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-evn-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-msi-light .theme-gj-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-msi-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-vt-light .theme-gj-dark .btn-secondary {
    border: solid 1px #00918d;
    border-radius: 2px; }
  .theme-vt-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-blue-light .theme-gj-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 2px; }
  .theme-blue-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #3790cf;
    border-radius: 0px; }
  .theme-hpu2-light .theme-gj-dark .btn-secondary {
    border: solid 1px #3952A1;
    border-radius: 2px; }
  .theme-hpu2-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-gj-light .theme-gj-dark .btn-secondary {
    border: solid 1px #6f0766;
    border-radius: 2px; }
  .theme-gj-dark .theme-gj-dark .btn-secondary {
    border: solid 1px #92c36a;
    border-radius: 0px; }
  .theme-default-light .theme-gj-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-default-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-exam .theme-gj-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-sb-light .theme-gj-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-sb-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-evn-light .theme-gj-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-evn-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-msi-light .theme-gj-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-msi-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-vt-light .theme-gj-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-vt-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #00918d; }
  .theme-blue-light .theme-gj-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-blue-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #3790cf; }
  .theme-hpu2-light .theme-gj-dark .btn-secondary:hover {
    border-color: #2c3972; }
  .theme-hpu2-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #92c36a; }
  .theme-gj-light .theme-gj-dark .btn-secondary:hover {
    border-color: #6f0766; }
  .theme-gj-dark .theme-gj-dark .btn-secondary:hover {
    border-color: #6f0766; }

.theme-exam .btn-secondary:hover {
  background: #6f0766;
  color: #fff;
  border-color: #6f0766; }

.theme-sb-light .btn-secondary:hover {
  background: #c4261c;
  color: #fff;
  border-color: #c4261c; }

.theme-sb-dark .btn-secondary:hover {
  background: #c4261c;
  color: #fff;
  border-color: #c4261c; }

.theme-evn-light .btn-secondary:hover {
  background: #164e99;
  color: #fff;
  border-color: #164e99; }

.theme-evn-dark .btn-secondary:hover {
  background: #164e99;
  color: #fff;
  border-color: #164e99; }

.theme-msi-light .btn-secondary:hover {
  background: #00ace9;
  color: #fff;
  border-color: #00ace9; }

.theme-msi-dark .btn-secondary:hover {
  background: #00ace9;
  color: #fff;
  border-color: #00ace9; }

.theme-vt-light .btn-secondary:hover {
  background: #00918d;
  color: #fff;
  border-color: #00918d; }

.theme-vt-dark .btn-secondary:hover {
  background: #00918d;
  color: #fff;
  border-color: #00918d; }

.theme-blue-light .btn-secondary:hover {
  background: #3790cf;
  color: #fff;
  border-color: #3790cf; }

.theme-blue-dark .btn-secondary:hover {
  background: #3790cf;
  color: #fff;
  border-color: #3790cf; }

.theme-hpu2-light .btn-secondary:hover {
  background: #96251f;
  color: #fff;
  border-color: #96251f; }

.theme-hpu2-dark .btn-secondary:hover {
  background: #96251f;
  color: #fff;
  border-color: #96251f; }

.theme-gj-light .btn-secondary:hover {
  background: #6f0766;
  color: #fff;
  border-color: #6f0766; }

.theme-gj-dark .btn-secondary:hover {
  background: #6f0766;
  color: #fff;
  border-color: #6f0766; }

.primary-button {
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  border-radius: 0;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  vertical-align: middle;
  text-decoration: none;
  outline: none; }
  .theme-default-light .primary-button {
    background: !important;
    color: !important;
    border: 1px solid; }
  .theme-default-dark .primary-button {
    background: !important;
    color: !important;
    border: 1px solid; }
  .theme-exam .primary-button {
    background: #6f0766 !important;
    color: #fff !important;
    border: 1px solid #6f0766; }
  .theme-sb-light .primary-button {
    background: #c4261c !important;
    color: #fff !important;
    border: 1px solid #c4261c; }
  .theme-sb-dark .primary-button {
    background: #c4261c !important;
    color: #fff !important;
    border: 1px solid #c4261c; }
  .theme-evn-light .primary-button {
    background: #164e99 !important;
    color: #fff !important;
    border: 1px solid #164e99; }
  .theme-evn-dark .primary-button {
    background: #164e99 !important;
    color: #fff !important;
    border: 1px solid #164e99; }
  .theme-msi-light .primary-button {
    background: #00ace9 !important;
    color: #fff !important;
    border: 1px solid #00ace9; }
  .theme-msi-dark .primary-button {
    background: #00ace9 !important;
    color: #fff !important;
    border: 1px solid #00ace9; }
  .theme-vt-light .primary-button {
    background: #00918d !important;
    color: #fff !important;
    border: 1px solid #00918d; }
  .theme-vt-dark .primary-button {
    background: #00918d !important;
    color: #fff !important;
    border: 1px solid #00918d; }
  .theme-blue-light .primary-button {
    background: #3790cf !important;
    color: #fff !important;
    border: 1px solid #3790cf; }
  .theme-blue-dark .primary-button {
    background: #3790cf !important;
    color: #fff !important;
    border: 1px solid #3790cf; }
  .theme-hpu2-light .primary-button {
    background: #96251f !important;
    color: #fff !important;
    border: 1px solid #96251f; }
  .theme-hpu2-dark .primary-button {
    background: #96251f !important;
    color: #fff !important;
    border: 1px solid #96251f; }
  .theme-gj-light .primary-button {
    background: #6f0766 !important;
    color: #fff !important;
    border: 1px solid #6f0766; }
  .theme-gj-dark .primary-button {
    background: #6f0766 !important;
    color: #fff !important;
    border: 1px solid #6f0766; }
  .theme-default-light .primary-button:hover {
    background: !important;
    color: !important;
    border: 1px solid; }
  .theme-default-dark .primary-button:hover {
    background: !important;
    color: !important;
    border: 1px solid; }
  .theme-exam .primary-button:hover {
    background: #fff !important;
    color: #6f0766 !important;
    border: 1px solid #6f0766; }
  .theme-sb-light .primary-button:hover {
    background: #fff !important;
    color: #c4261c !important;
    border: 1px solid #c4261c; }
  .theme-sb-dark .primary-button:hover {
    background: #fff !important;
    color: #c4261c !important;
    border: 1px solid #c4261c; }
  .theme-evn-light .primary-button:hover {
    background: #fff !important;
    color: #164e99 !important;
    border: 1px solid #164e99; }
  .theme-evn-dark .primary-button:hover {
    background: #fff !important;
    color: #164e99 !important;
    border: 1px solid #164e99; }
  .theme-msi-light .primary-button:hover {
    background: #fff !important;
    color: #00ace9 !important;
    border: 1px solid #00ace9; }
  .theme-msi-dark .primary-button:hover {
    background: #fff !important;
    color: #00ace9 !important;
    border: 1px solid #00ace9; }
  .theme-vt-light .primary-button:hover {
    background: #fff !important;
    color: #00918d !important;
    border: 1px solid #00918d; }
  .theme-vt-dark .primary-button:hover {
    background: #fff !important;
    color: #00918d !important;
    border: 1px solid #00918d; }
  .theme-blue-light .primary-button:hover {
    background: #fff !important;
    color: #3790cf !important;
    border: 1px solid #3790cf; }
  .theme-blue-dark .primary-button:hover {
    background: #fff !important;
    color: #3790cf !important;
    border: 1px solid #3790cf; }
  .theme-hpu2-light .primary-button:hover {
    background: #fff !important;
    color: #96251f !important;
    border: 1px solid #96251f; }
  .theme-hpu2-dark .primary-button:hover {
    background: #fff !important;
    color: #96251f !important;
    border: 1px solid #96251f; }
  .theme-gj-light .primary-button:hover {
    background: #fff !important;
    color: #6f0766 !important;
    border: 1px solid #6f0766; }
  .theme-gj-dark .primary-button:hover {
    background: #fff !important;
    color: #6f0766 !important;
    border: 1px solid #6f0766; }
