.download-mobile-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    text-align: center;
  }
  &__download-link {
    margin: 5px 15px;
    img {
      height: 50px;
      max-width: 100%;
    }
  }

  &--small-width {
    .download-mobile-app__download-link {
      display: block;
      margin: 0 0 10px 0;
    }
  }
}
