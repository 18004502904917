
.ui-timetable-ve-panel {
  background-color: white;
  .hidden {
    display: none;
  }
  .date-header {
    position: relative;
    width: 100%;
    margin: 0 .5em;
    height: 100%;
    .day-number {
      font-size: 200%;
    }
    .month-year {
      text-align: left;
      opacity: 0.5;
      font-style: initial;
      font-size: 80%;
    }
  }

  .saturday-bg-color {
    background-color: #f3f1f1;
  }

  .sunday-bg-color {
    background-color: #dcdfe0;
  }

  .header-of-timetable {
    display: flex;
    padding: 8px 20px;
    .left {
      flex-grow: 1;
      .viewer-form {
        display: flex;
        font-size: 25px;
        .control-item-tt {
          padding-right: 10px;
          margin: 0;
        }
        .icon-date {
          padding-right: 10px;
          margin: 0;
          cursor: pointer;
          align-self: center;
        }
      }

    }
    .item-icon {
      margin-right: 10px;
    }
  }

  .session-des {
    display: flex;
    .left {
      flex-grow: 1;
    }
  }

  .setting-panel {
    display: flex;

  }

  .bg1 {
    background-color: #f1f8ff !important;
  }
  .bg2 {
    background-color: #fffdf1 !important;
  }

  .timeslot-header {
    position: relative;
    width: 100%;
    height: 100%;
    .timeslot-name {
      font-size: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .room-name {
      opacity: 0.5;
      font-style: initial;
      font-size: 10px;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }

  div {
    &:focus {
      outline: 0px solid transparent !important;
    }
  }
  .inactive-cell {
    border: 1px solid #a0a3a5 !important;
    background-color: #c2ccd1 !important;
  }
  .event-cell {
    border: 1px solid #cd945c !important;
    background-color: #cd5c5c !important;
  }
  .course-session-cell {
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    //top: 1px;
    //left: 1px;
    width: calc(100% - 1px);
    border: 1px solid #a0a3a5;
    background-color: #039be9;


    overflow: hidden;

    .remove-icon {
      right: 2px;
      position: absolute;
      top: 2px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    .credit-syllabus-code {
      font-weight: bold;
      padding-left: 3px;
      cursor: pointer;
      flex-grow: 1;
    }
    &:hover {
      border: 1px solid #0077CC;
      background-color: #039be9;
      color: #ffffff;
      //min-height: 60px;
      width: 100% !important;
      box-shadow: 0px 7px 5px -7px #0077CC;
      //transform: translateY(-5px);
      //height: call(100% + 5);
      z-index: 1450 !important;
      //top: calc(100% + 10px);
    }

    .footer-slot-box {
      display: flex;
      width: 100%;
      padding: 3px 2px;
      align-self: flex-end;
      .class-panel {
        flex-grow: 1;
      }
    }
  }
  .timetable-slot-cell {
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    //top: 1px;
    //left: 1px;
    width: calc(100% - 1px);
    border: 1px solid #a0a3a5;
    background-color: #039be9;


    overflow: hidden;

    .remove-icon {
      right: 2px;
      position: absolute;
      top: 2px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    .credit-syllabus-code {
      font-weight: bold;
      padding-left: 3px;
      cursor: pointer;
      flex-grow: 1;
    }
    &:hover {
      border: 1px solid #0077CC;
      background-color: #039be9;
      color: #ffffff;
      //min-height: 60px;
      width: 100% !important;
      box-shadow: 0px 7px 5px -7px #0077CC;
      //transform: translateY(-5px);
      //height: call(100% + 5);
      z-index: 1450 !important;
      //top: calc(100% + 10px);
    }

    .footer-slot-box {
      display: flex;
      width: 100%;
      padding: 3px 2px;
      align-self: flex-end;
      .class-panel {
        flex-grow: 1;
      }
    }
  }

  .GridRow {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .GridColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .LeftSideGridContainer {
    flex: 0 0 75px;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(1px);
    //border: 1px solid #cccccc;
    //background-color: #cccccc;
  }

  .LeftSideGrid {
    overflow: hidden !important;
  }
  .HeaderGrid {
    width: 100%;
    overflow: hidden !important;
    //background-color: #cccccc;
    font-weight: bold;
  }
  .BodyGrid {
    width: 100%;
    cursor: pointer;
  }

  .evenRow {
  }
  .oddRow {
    background-color: rgba(0, 0, 0, .1);
  }

  .leftHeader {
    transform: translateY(1px);
  }

  .cell,
  .headerCell,
  .leftCell {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

  }

  .b-l-cel {
    border-left: 1px solid #c2ccd1;
  }

  .b-r-cel {
    border-right: 1px solid #c2ccd1;
  }
  .b-t-cel {
    border-top: 1px solid #c2ccd1;
  }
  .b-b-cel {
    border-bottom: 1px solid #c2ccd1;
  }
  .headerCell,
  .leftCell {
    font-weight: bold;
    background-color: white;
  }
}
