@import "../../../../layouts/theme.scss";

.detail-a-comment {
  padding: 10px 0;
  display: flex;
  @include themify($themes){
    color: themed('comment-main-cl');
  }
  flex-direction: row;
  //.avatar {
  //  margin-top: 4px;
  //  min-width: 40px;
  //}

  .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    .style-resolved {
      @include themify($themes){
        color: themed('comment-main-cl');
      }
    }
    .bar {
      width: 100%;
      display: flex;
      margin-bottom: 2px;
      .name {
        color: #009EE5;
        font-weight: 500;
        margin-right: 5px;
      }
      .date-time {
        flex-grow: 1;
        font: {
          size: 12px;
        };
        padding-top: 3px;
        padding-left: 10px;
      }
      .resolve-button {
        border: none;
        margin: 4px 0 4px 4px;
        border-radius: 4px;
        font-weight: 400;
        outline: none;
      }
      .resolve {
        div{
          span{
            @include themify($themes){
              color: themed('l-button-nav-cl');
            }
          }
        }
      }
      .resolved {
        background: transparent;
        @include themify($themes){
          color: themed('comment-main-cl');
        }
        padding: 0 17px;
        font-size: 14px;
      }
      .resolved-admin {
        background: transparent;
        @include themify($themes){
          color: themed('comment-main-cl');
        }
        padding: 0 17px;
        font-size: 14px;
      }
    }
    .content {
      @include themify($themes){
        color: themed('comment-main-cl');
      }
      //border: solid 1px #dce4e9;
      padding: 10px 0;
      border-radius: 4px;
      width: 100%;
      white-space: pre-wrap;
    }
  }
}