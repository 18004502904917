$theme-colors: (
        'primary': (
                'base': #00ace9,
                'light': #00ace9,
                'dark': #00ace9,
        ),
        'secondary': (
                'base': #005F7E,
                'light': #005F7E,
                'dark': #005F7E,
        ),
        'background': (
                'base': #fff,
                'light': #fff,
                'dark': #fff,
        ),
);

@function theme-color($key: 'primary', $variant: 'base') {
  $map: map-get($theme-colors, $key);
  @return map-get($map, $variant);
}

$msi : (
        msi-light: (
          // admin
                a-primary-cl: theme-color(),
                a-second-cl: theme-color('secondary', 'base'),
                a-bg-cl: theme-color('background', 'base'),
                a-bg-cl-hover: theme-color('background', 'base'),
                a-bg-cl-active: theme-color('background', 'base'),
                a-bg-cl-focus: theme-color('background', 'base'),
                a-bg-cl-visited: theme-color('background', 'base'),
                a-text-cl: theme-color(),
                a-text-cl-hover: theme-color(),
                a-text-cl-active: theme-color(),
                a-text-cl-focus: theme-color(),
                a-text-cl-visited: theme-color(),
                a-btn-cl-hover: theme-color(),
                a-btn-cl-active: theme-color(),
                a-btn-cl-focus: theme-color(),
                a-btn-cl-visited: theme-color(),
                a-border-top-menu: 1px solid theme-color(),
          //
                l-main-bg: white,
                l-control: white,
                l-exercise-overview: #FBFBFB,
                l-exercise-overview-border: #e7e7e7,
                l-nav-bg-color: white,
                l-track-vertical: #e7e7e7,
                l-thumb-vertical-bg: rgba(0, 0, 0, 0.2),
                l-nav-item-cl: black,
                l-zone-border: #dddddd,
                l-quiz-conent-icon: black,
                l-quiz-conent-h3: black,
                login-form-indicator:theme-color(),
          //text
                text-cl-hover: theme-color(),
          // border
                feature-courses-border: 2px solid theme-color(),
                border-bottom-color: 3px solid theme-color(),
          //border-radius: 100%,
          // li
                li-bg-hover: #f2f3f5,
                li-cl-hover: #c4261c,
                li-bg-selected: #ffccc7,
                li-cl-selected: #c4261c,
          //button
                btn-primary-bg: theme-color(),
                btn-text-cl: theme-color('background', 'base'),
                btn-login-bg: theme-color(),
                l-button-nav-bg: #e7e7e7,
                l-button-nav-bg-hover: #e7e7e7,
                l-button-nav-bg-hover-1: #e7e7e7,
                l-button-nav-bg-hover-2: #bcbcbc,
                l-button-nav-cl-hover-2: rgba(0, 0, 0, 0.3),
                l-button-nav-cl: black,
                l-button-nav-cl-1: #00bcd4,
                l-button-nav-cl-hover: black,
                l-button-nav-cl-hover-1: rgba(0, 0, 0, 0.3),
                l-button-nav-border: transparent,
                l-button-filled-bg: #e7e7e7,
                l-button-filled-border: #e7e7e7,
                l-button-filled-cl: black,
                l-button-filled-hover-cl: black,
                l-button-filled-hover-bg: #bcbcbc,
                l-button-filled-hover-border: #bcbcbc,
                l-button-filled-border-radius-dimen: 2px,
                l-button-primary-bg: #3952A1,
                l-button-primary-border: #3952A1,
                l-button-primary-cl: white,
                l-button-primary-hover-cl: white,
                l-button-primary-hover-bg: #2c3972,
                l-button-primary-hover-border: #2c3972,
                l-button-primary-border-radius-dimen: 2px,
                l-question-button-disable-cl: rgb(174, 174, 173),
          // item menu
                l-menu-item-header-bg: #e7e7e7,
                l-menu-item-header-cl: black,
                l-menu-item-bg-active: #92b773,
                l-menu-item-bg-active-1: #cde3bb,
                l-menu-item-playing-or-next-bg: #c9c9c9,
                l-menu-item-playing-or-next-cl: black,
          //dev
                l-nav-dev-bg: white,
                l-dev-button-bg: transparent,
                l-dev-button-cl: black,
                l-dev-button-bg-active: #e7e7e7,
                l-dev-button-cl-active: black,
          // question
                l-question-cl: black,
                l-question-clickable-cl: black,
                l-question-hover-bg: #e7e7e7,
                l-question-ticked-bg: #e8d060,
                l-question-correct-bg: #92c36a,
                l-question-incorrect-bg:#f05252,
                l-question-done-bg: #427bc9,
                l-question-touched-bg: #969696,
                l-question-current-bg: #92c36a,
                l-question-listening-bg: #1B5E20,
                l-question-height: 0,
          // summary course
                l-summary-course-session-bg: rgba(224, 224, 224, 0.24),
                menu-top-bg: theme-color(),
                l-summary-course-feature-boxed: white,
                l-course-overview-content-cl: #19191d,
          // in progress
                l-progress-finish:rgba(146, 195, 106, 0.85),
                l-progress-improvement: #bbb, // rgba(239, 140, 0, 0.85), //rgba(240, 173, 78, 0.85),
                l-progress-lock: rgba(217, 83, 79, 0.85),
                l-progress-bad: #bbb, // rgba(217, 83, 79, 0.85),
          // PDF
                l-button-pdf-cl: #6d6d6d,
                l-button-pdf-cl-disable: #E0E0E0,
          // comment
                comment-main-cl: #303030,
                comment-action-hover-bg: #ededed,
                active-color: theme-color(),
                active-item-background-color: #e6f7ff,
                close-button-color: theme-color(),
                complete-icon-background-color: theme-color(),
                course-title-percent-text-color: theme-color(),
                over-deadline-color: red,
                primary-text-color: theme-color(),
                progress-color-learn: theme-color(),
                sco-background-color--hover: #eee,
                sco-background-color: #e7e7e7,
                sco-text-color: #504e4e,
                tab-background-color: #ffffff,
                tab-text-active-color: theme-color(),
                tab-text-color: #504e4e,
                tab-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1),
                tiny-progress-color: theme-color(),
                pagination-text-color: theme-color(),
                pagination-active-text-color: #fff,
                pagination-acive-background-color: theme-color(),
        ),
        msi-dark: (
          // admin
                a-primary-cl: theme-color(),
                a-second-cl: theme-color('secondary', 'base'),
                a-bg-cl: theme-color('background', 'base'),
                a-bg-cl-hover: theme-color('background', 'base'),
                a-bg-cl-active: theme-color('background', 'base'),
                a-bg-cl-focus: theme-color('background', 'base'),
                a-bg-cl-visited: theme-color('background', 'base'),
                a-text-cl: theme-color(),
                a-text-cl-hover: theme-color(),
                a-text-cl-active: theme-color(),
                a-text-cl-focus: theme-color(),
                a-text-cl-visited: theme-color(),
                a-btn-cl-hover: theme-color(),
                a-btn-cl-active: theme-color(),
                a-btn-cl-focus: theme-color(),
                a-btn-cl-visited: theme-color(),
                a-border-top-menu: 1px solid theme-color(),
          //
                l-nav-bg-color: #292929,
                l-main-bg: #292929,
                l-control: #272525,
                l-exercise-overview: black,
                l-exercise-overview-border: black,
                l-track-vertical: rgba(0, 0, 0, 0.4),
                l-thumb-vertical-bg: hsla(0, 0%, 100%, .5),
                l-nav-item-cl: white,
                l-zone-border: #292929,
                l-quiz-conent-icon: white,
                l-quiz-conent-h3: white,
                login-form-indicator:theme-color(),
          //text
                text-cl-hover: theme-color(),
          // li
                li-bg-hover: #f2f3f5,
                li-cl-hover: #c4261c,
                li-bg-selected: #ffccc7,
                li-cl-selected: #c4261c,
          // border
                feature-courses-border: 2px solid theme-color(),
                border-bottom-color: 3px solid theme-color(),
          //border-radius: 100%,
          //button
                btn-primary-bg: theme-color(),
                btn-text-cl: theme-color('background', 'base'),
                btn-login-bg: theme-color(),
                l-button-nav-bg: transparent,
                l-button-nav-bg-hover: white,
                l-button-nav-bg-hover-1: rgba(255, 255, 255, 0.1),
                l-button-nav-cl-hover-2: #333333,
                l-button-nav-cl: white,
                l-button-nav-cl-1: #00bcd4,
                l-button-nav-cl-hover: #222,
                l-button-nav-cl-hover-1: white,
                l-button-nav-border: rgba(255, 255, 255, 0.3),
                l-button-filled-bg: transparent,
                l-button-filled-border: #92c36a,
                l-button-filled-hover-bg: #92c36a,
                l-button-filled-hover-border: #92c36a,
                l-button-filled-cl: #92c36a,
                l-button-filled-hover-cl: white,
                l-button-filled-border-radius-dimen: 0px,
                l-button-primary-bg: transparent,
                l-button-primary-border: #92c36a,
                l-button-primary-hover-cl: white,
                l-button-primary-hover-bg: #92c36a,
                l-button-primary-hover-border: #92c36a,
                l-button-primary-cl: #92c36a,
                l-button-primary-border-radius-dimen: 0px,
                l-question-button-disable-cl: rgba(255, 255, 255, 0.3),
          //item menu
                l-menu-item-header-bg: #666666,
                l-menu-item-header-cl: white,
                l-menu-item-bg-active: #92c36a,
                l-menu-item-bg-active-1: rgba(146, 195, 106, 0.2),
                l-menu-item-playing-or-next-bg: #131313,
                l-menu-item-playing-or-next-cl: white,
          //dev
                l-nav-dev-bg: transparent,
                l-dev-button-bg: transparent,
                l-dev-button-cl: white,
                l-dev-button-bg-active: white,
                l-dev-button-cl-active: grey,
          // question
                l-question-cl: rgba(255, 255, 255, 0.3),
                l-question-clickable-cl: white,
                l-question-hover-bg: white,
                l-question-ticked-bg: #e8d060,
                l-question-correct-bg: #92c36a,
                l-question-incorrect-bg:#f05252,
                l-question-done-bg: #427bc9,
                l-question-touched-bg: #969696,
                l-question-current-bg: #92c36a,
                l-question-listening-bg: #1B5E20,
                l-question-height: 0,
          // summary course
                l-summary-course-session-bg: white,
                menu-top-bg: theme-color('primary', 'dark'),
                l-summary-course-feature-boxed: #f8f8f8,
                l-course-overview-content-cl: white,
          // in progress
                l-progress-finish:rgba(146, 195, 106, 0.85),
                l-progress-improvement: rgba(240, 173, 78, 0.85),
                l-progress-lock: rgba(217, 83, 79, 0.85),
                l-progress-bad:rgba(217, 83, 79, 0.85),
          // PDF
                l-button-pdf-cl: #E0E0E0,
                l-button-pdf-cl-disable: #6d6d6d,
          // comment
                comment-main-cl: #eaeaea,
                comment-action-hover-bg: #575757,
        ),
        exam: (
          // admin
                a-primary-cl: theme-color(),
                a-second-cl: theme-color('secondary', 'base'),
                a-bg-cl: theme-color('background', 'base'),
                a-bg-cl-hover: theme-color('background', 'base'),
                a-bg-cl-active: theme-color('background', 'base'),
                a-bg-cl-focus: theme-color('background', 'base'),
                a-bg-cl-visited: theme-color('background', 'base'),
                a-text-cl: theme-color(),
                a-text-cl-hover: theme-color(),
                a-text-cl-active: theme-color(),
                a-text-cl-focus: theme-color(),
                a-text-cl-visited: theme-color(),
                a-btn-cl-hover: theme-color(),
                a-btn-cl-active: theme-color(),
                a-btn-cl-focus: theme-color(),
                a-btn-cl-visited: theme-color(),
                a-border-top-menu: 1px solid theme-color(),
          //
                l-main-bg: white,
                l-control: white,
                l-exercise-overview: white,
                l-exercise-overview-border: #e7e7e7,
                l-nav-bg-color: white,
                l-track-vertical: #e7e7e7,
                l-thumb-vertical-bg: rgba(0, 0, 0, 0.2),
                l-nav-item-cl: black,
                l-zone-border: #dddddd,
                l-quiz-conent-icon: black,
                l-quiz-conent-h3: black,
                login-form-indicator:theme-color(),
          //text
                text-cl-hover: theme-color(),
          // li
                li-bg-hover: #f2f3f5,
                li-cl-hover: #c4261c,
                li-bg-selected: #ffccc7,
                li-cl-selected: #c4261c,
          // border
                feature-courses-border: 2px solid theme-color(),
                border-bottom-color: 3px solid theme-color(),
          //border-radius: 100%,
          //button
                btn-primary-bg: theme-color(),
                btn-text-cl: theme-color('background', 'base'),
                btn-login-bg: theme-color(),
                l-button-nav-bg: #e7e7e7,
                l-button-nav-bg-hover: #e7e7e7,
                l-button-nav-bg-hover-1: #e7e7e7,
                l-button-nav-cl-hover-2: rgba(0, 0, 0, 0.3),
                l-button-nav-cl: black,
                l-button-nav-cl-1: #00bcd4,
                l-button-nav-cl-hover: black,
                l-button-nav-cl-hover-1: rgba(0, 0, 0, 0.3),
                l-button-nav-border: transparent,
                l-button-filled-bg: #e7e7e7,
                l-button-filled-border: #e7e7e7,
                l-button-filled-cl: black,
                l-button-filled-hover-cl: black,
                l-button-filled-hover-bg: #bcbcbc,
                l-button-filled-hover-border: #bcbcbc,
                l-button-filled-border-radius-dimen: 2px,
                l-button-primary-bg: #3952A1,
                l-button-primary-border: #3952A1,
                l-button-primary-cl: white,
                l-button-primary-hover-cl: white,
                l-button-primary-hover-bg: #2c3972,
                l-button-primary-hover-border: #2c3972,
                l-button-primary-border-radius-dimen: 2px,
                l-question-button-disable-cl: rgb(174, 174, 173),
          // item menu
                l-menu-item-header-bg: #e7e7e7,
                l-menu-item-header-cl: black,
                l-menu-item-bg-active: #92b773,
                l-menu-item-bg-active-1: #cde3bb,
                l-menu-item-playing-or-next-bg: #c9c9c9,
                l-menu-item-playing-or-next-cl: black,
          //dev
                l-nav-dev-bg: white,
                l-dev-button-bg: transparent,
                l-dev-button-cl: black,
                l-dev-button-bg-active: #e7e7e7,
                l-dev-button-cl-active: black,
          // question
                l-question-cl: black,
                l-question-clickable-cl: black,
                l-question-hover-bg: #e7e7e7,
                l-question-ticked-bg: #e8d060,
                l-question-correct-bg: #92c36a,
                l-question-incorrect-bg:#f05252,
                l-question-done-bg: #427bc9,
                l-question-touched-bg: #969696,
                l-question-current-bg: #92c36a,
                l-question-listening-bg: #1B5E20,
                l-question-height: unset,
          // summary course
                l-summary-course-session-bg: rgba(224, 224, 224, 0.24),
                menu-top-bg: theme-color(),
                l-summary-course-feature-boxed: white,
                l-course-overview-content-cl: #19191d,
          // in progress
                l-progress-finish:rgba(146, 195, 106, 0.85),
                l-progress-improvement: rgba(239, 140, 0, 0.85), //rgba(240, 173, 78, 0.85),
                l-progress-lock: rgba(217, 83, 79, 0.85),
                l-progress-bad:rgba(217, 83, 79, 0.85),
          // PDF
                l-button-pdf-cl: #6d6d6d,
                l-button-pdf-cl-disable: #E0E0E0,
          // comment
                comment-main-cl: #303030,
                comment-action-hover-bg: #ededed,
          //
        )
);

