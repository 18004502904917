@import '../../common/define.scss';

.role-play-main-control {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f1f1f1;
  &__buttons {
    height: 70%;
    .mic{
      float: right;
      height: 100%;
      button{
        background-color: #fff !important;
        i{
          color: #e68a4c;
        }
      }
    }
  }

  &__button {
    margin: 0 1em;
    .mi {
      font-size: 3em;
      color: white;
    }

    button {
      background-color: $role-play-primary-button-color !important;
      i{
        margin-right: 0 !important;
      }
    }
  }
}