.ql-editor{
  min-height: 150px !important;
  max-height: 450px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
  //img {
  //  width: 200px;
  //}

  p {
    line-height: 1.78571;
  }
}