.ant-form-select-option {
  .input-text-label {
    color: #B2B2B2;
    line-height: 36px;
    margin-bottom: -9px;
  }

  margin-bottom: 0;

  .full-width {
    width: 100%
  }
}
.ant-select-dropdown {
  z-index: 2100;
}