@import "../../common/define.scss";

.role-play-character {
  background-color: transparent;
  border: none;
  outline: none;

  &--active {
    img {
      border: solid 3px $role-play-primary-button-color;
    }
  }

  &__avatar {

    &--clickable {
      &:hover {
        img {
          border: 3px solid rgb(230, 138, 76);
        }
      }
    }

  }

  &__name {
    text-transform: capitalize;
    padding: 5px;
  }
}