.learn-sub-board-section-card {
  .ant-card-head {
    background: rgb(215, 215, 215);
    height: 40px;
    min-height: 0;
    font-size: 13px;
    font-weight: 600;

    .ant-card-head-title {
      padding: 10px 0;
    }
  }
}