@import '../../common/define.scss';

.role-play-speaking {
  &__buttons {
    padding-right: 10px;
  }

  &__button {
    background-color: transparent;
    border: none;

    &:hover {
      color: $role-play-primary-button-color;
    }

    &--right {
      float: right;
    }
  }
}