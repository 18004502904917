@import 'layouts/theme.scss';

.normal-exercise-control-question-groups {
  width: 100% !important;

  &__groups {
    height: 80%;
    margin: 0.2em;
    display: flex;
  }

  &__group {
    margin-left: 0.2em;
    margin-right: 0.2em;
    //padding: .8em;
    border-radius: 50px;
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline;
    padding: 0 5px;
    line-height: 45px;
    float: left;

    &--active {
      border-color: #c5e1a5;
    }

    &--single {
      border-color: transparent;
      padding: 0;
      margin-right: 0;
      margin-left: 0;
      float: left;
    }
  }

  &__question {
    font-size: 1.2em;
    margin: 0.2em;
    border-radius: 100px;
    background-color: transparent;
    border: solid 1px #9a9a9a;
    outline: none;
    cursor: not-allowed;
    min-width: 30px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: initial;
    height: 30px;
    line-height: 0;
    @include themify($themes) {
      color: themed('l-question-cl');
    }

    &--clickable {
      cursor: pointer;
      @include themify($themes) {
        color: themed('l-question-clickable-cl');
      }

      &:hover {
        background-color: white;
        color: #333333;
      }
    }

    &--ticked {
      @include themify($themes) {
        background-color: themed('l-question-ticked-bg');
        border-color: themed('l-question-ticked-bg');
      }
    }

    &--correct {
      @include themify($themes) {
        background-color: themed('l-question-correct-bg');
        border-color: themed('l-question-correct-bg');
      }
    }

    &--incorrect {
      @include themify($themes) {
        background-color: themed('l-question-incorrect-bg');
        border-color: themed('l-question-incorrect-bg');
      }
    }

    &--ordered {
      @include themify($themes) {
        background-color: themed('l-question-ordered-bg');
        border-color: themed('l-question-ordered-bg');
      }
    }

    &--done {
      @include themify($themes) {
        background-color: themed('l-question-done-bg');
        border-color: themed('l-question-done-bg');
      }
    }

    &--touched {
      @include themify($themes) {
        background-color: themed('l-question-touched-bg');
        border-color: themed('l-question-touched-bg');
      }
    }

    &--current {
      @include themify($themes) {
        background-color: themed('l-question-current-bg');
        border-color: themed('l-question-current-bg');
      }
    }

    &--listening {
      @include themify($themes) {
        background-color: themed('l-question-listening-bg');
        border-color: themed('l-question-listening-bg');
      }
    }

    &--current.normal-exercise-control-question-groups__question--correct,
    &--current.exam-exercise-control-question-groups__question--correct {
      @include themify($themes) {
        background-color: themed('bg-match') !important;
        color: themed('color-match') !important;
      }
    }

    &--current.normal-exercise-control-question-groups__question--incorrect,
    &--current.exam-exercise-control-question-groups__question--incorrect {
      @include themify($themes) {
        background-color: themed('bg-not-match') !important;
        color: themed('color-not-match') !important;
      }
    }
  }
}

.exam-exercise-control-question-groups {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: stretch;
  flex-flow: row wrap;

  &__groups {
    height: 80%;
    margin: 0.2em;
    display: flex;
  }

  &__group {
    margin-left: 0.2em;
    margin-right: 0.2em;
    padding: 0.2em;
    margin: 0.1em;
    display: inline;
    border-radius: 0;
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);

    &--active {
      border-color: #c5e1a5;
    }

    &--single {
      border-color: transparent;
      margin-right: 0;
      margin-left: 0;
    }
  }
  &__question {
    margin: 0.2em;
    border-radius: 50%;
    background-color: transparent;
    border: solid 1px #9a9a9a;
    outline: none;
    cursor: not-allowed;
    min-width: 32px;
    min-height: 32px;
    height: 32px;
    text-align: center;
    transition: {
      property: all;
      duration: 0.3s;
      timing-function: ease;
      delay: initial;
    }
    color: white;
    line-height: 32px;
    font: {
      family: 'Roboto', sans-serif;
      weight: bold;
    }

    &--clickable {
      cursor: pointer;
      color: white;

      &:hover {
        background-color: white;
        color: #333333;
      }
    }

    &--ticked {
      @include themify($themes) {
        background-color: themed('l-question-ticked-bg');
        border-color: themed('l-question-ticked-bg');
      }
    }

    &--correct {
      @include themify($themes) {
        background-color: themed('l-question-correct-bg');
        border-color: themed('l-question-correct-bg');
      }
    }

    &--incorrect {
      @include themify($themes) {
        background-color: themed('l-question-incorrect-bg');
        border-color: themed('l-question-incorrect-bg');
      }
    }

    &--done {
      @include themify($themes) {
        background-color: themed('l-question-done-bg');
        border-color: themed('l-question-done-bg');
      }
    }

    &--touched {
      @include themify($themes) {
        background-color: themed('l-question-touched-bg');
        border-color: themed('l-question-touched-bg');
      }
    }

    &--current {
      @include themify($themes) {
        background-color: themed('l-question-current-bg');
        border-color: themed('l-question-current-bg');
      }
    }

    &--listening {
      @include themify($themes) {
        background-color: themed('l-question-listening-bg');
        border-color: themed('l-question-listening-bg');
      }
    }

    &--current.normal-exercise-control-question-groups__question--correct,
    &--current.exam-exercise-control-question-groups__question--correct {
      @include themify($themes) {
        background-color: themed('bg-match') !important;
        color: themed('color-match') !important;
      }
    }

    &--current.normal-exercise-control-question-groups__question--incorrect,
    &--current.exam-exercise-control-question-groups__question--incorrect {
      @include themify($themes) {
        background-color: themed('bg-not-match') !important;
        color: themed('color-not-match') !important;
      }
    }
  }
}
