.ant-checkbox-multiline {
  padding-top: 10px !important;
  .ant-checkbox-group-item {
    display: block;
    margin: 5px 0;
  }
}
.ant-multi-checkbox {
  .input-text-label {
    color: #b2b2b2;
    line-height: 36px;
    margin-bottom: -9px;
  }
}