$color : #e13542;
.ums-login-form{
  padding: 16px 32px 32px 32px;
  margin-bottom: 24px;
  a{
    &:hover{
      color: $color;
    }
  }
  .title{
    margin-top: -30px;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
  }
  .text-field{
    margin-top: -20px;
  }
  .action{
    display: flex;
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
    .forgot-password{
      cursor: pointer;
    }
  }
  .btn{
    background: $color;
    border: none;
    padding: 5px 16px;
    outline: none;
    text-transform: uppercase;
    color: #e2e2e2;
    font-size: 14px;
    font-weight: 400;
    min-width: 100px;
    margin: 16px auto;
    &:hover{
      color: white;
    }
  }
}